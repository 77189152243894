export class Currency extends String {
    constructor() {
        super(...arguments);
        this.equals = (other) => other.toString() === this.toString();
    }
}
export const AED = new Currency('AED'); // United Arab Emirates Dirham
export const ANG = new Currency('ANG'); // Antillean guilder
export const ARS = new Currency('ARS'); // Argentine peso
export const AUD = new Currency('AUD'); // Australian Dollar
export const BIF = new Currency('BIF'); // Burundian franc
export const BRL = new Currency('BRL'); // Brazilian real
export const CAD = new Currency('CAD'); // Canadian dollar
export const CHF = new Currency('CHF'); // Swiss franc
export const CLP = new Currency('CLP'); // Chilean peso
export const COP = new Currency('COP'); // Colombian peso
export const EGP = new Currency('EGP'); // Egyptian Pound
export const ETB = new Currency('ETB'); // Ethiopian Birr
export const EUR = new Currency('EUR'); // European Euro
export const FJD = new Currency('FJD'); // Fijian Dollar
export const GBP = new Currency('GBP'); // United Kingdom Pound
export const GHS = new Currency('GHS'); // Ghanaian Cedi
export const GMD = new Currency('GMD'); // Gambian Dalasi
export const HKD = new Currency('HKD'); // Hong Kong dollar
export const HRK = new Currency('HRK'); // Croatian kuna
export const HUF = new Currency('HUF'); // Hungarian Forint
export const IDR = new Currency('IDR'); // Indonesian Rupiah
export const INR = new Currency('INR'); // Indian Rupee
export const ISK = new Currency('ISK'); // Icelandic Króna
export const JPY = new Currency('JPY'); // Japanese Yen
export const KES = new Currency('KES'); // Kenyan Shilling
export const KHR = new Currency('KHR'); // Cambodian Riel
export const KWD = new Currency('KWD'); // Kuwaiti Dinar
export const LAK = new Currency('LAK'); // Lao kip
export const LBP = new Currency('LBP'); // Lebanese Pound
export const LRD = new Currency('LRD'); // Liberian dollar
export const LKR = new Currency('LKR'); // Sri Lankan Rupee
export const MXN = new Currency('MXN'); // Mexican Peso
export const MYR = new Currency('MYR'); // Malaysian Ringgit
export const NGN = new Currency('NGN'); // Nigerian Naira
export const NOK = new Currency('NOK'); // Norwegian Krone
export const NPR = new Currency('NPR'); // Nepalese Rupee
export const PAB = new Currency('PAB'); // Panamanian Balboa
export const PEN = new Currency('PEN'); // Nuevo Sol
export const PHP = new Currency('PHP'); // Philippine peso
export const RON = new Currency('RON'); // Romanian leu
export const RUB = new Currency('RUB'); // Russian Ruble
export const RWF = new Currency('RWF'); // Rwandan franc
export const SEK = new Currency('SEK'); // Swedish Krona
export const SGD = new Currency('SGD'); // Singapore Dollar
export const SLL = new Currency('SLL'); // Sierra Leonean leone
export const SSP = new Currency('SSP'); // South Sudanese Pound
export const SZL = new Currency('SZL'); // Lilangeni
export const THB = new Currency('THB'); // Thai Baht
export const TRY = new Currency('TRY'); // Turkish Lira
export const TWD = new Currency('TWD'); // New Taiwan Dollar
export const TZS = new Currency('TZS'); // Tanzanian Shilling
export const UAH = new Currency('UAH'); // Ukrainian hryvnia
export const UGX = new Currency('UGX'); // Ugandan Shilling
export const USD = new Currency('USD'); // United States Dollar
export const VEF = new Currency('VEF'); // Venezuelan bolívar
export const VND = new Currency('VND'); // Vietnamese Dong
export const XAF = new Currency('XAF'); // Central African CFA franc
export const XOF = new Currency('XOF'); // West African CFA Franc
export const ZAR = new Currency('ZAR'); // South African Rand
export const ZMK = new Currency('ZMK'); // Zambian Kwacha
export const ZWL = new Currency('ZWL'); // Zimbabwean Dollar
export class Currencies extends Array {
    constructor() {
        super(...arguments);
        this.isValid = (elem) => {
            return this.some(el => el.equals(elem));
        };
        this.get = (elem) => {
            return this.find(el => el.equals(elem));
        };
    }
}
export const CURRENCIES = new Currencies(AED, ANG, ARS, AUD, BIF, BRL, CAD, CHF, CLP, COP, EGP, ETB, EUR, FJD, GBP, GHS, GMD, HKD, HRK, HUF, IDR, INR, ISK, JPY, KES, KHR, KWD, LAK, LBP, LRD, LKR, MXN, MYR, NGN, NOK, NPR, PAB, PEN, PHP, RON, RUB, RWF, SEK, SGD, SLL, SSP, SZL, THB, TRY, TWD, TZS, UAH, UGX, USD, VEF, VND, XAF, XOF, ZAR, ZMK, ZWL);
