import Vue from 'vue';
import Toasted from 'vue-toasted'
import '../variables.scss'

Vue.use(Toasted, {
    theme: 'toasted-primary',
    position: 'top-center',
    duration: 2500
})

Vue.toasted.register('qrError', 'Invalid code, please check and try again.', {
    duration: 5000,
    className: 'ourToastedColor',
    containerClass: 'ourToastedContainer',
})