import { firestoreInvoker, collection, query, onSnapshot, where } from "@empower-platform/general-frontend-shared-firebase-setup";
import { userService, facilityService } from '@/main';

const firestoreConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    firebaseEmulator: process.env.VUE_APP_FIREBASE_EMULATOR
}

const { auth, firestore } = firestoreInvoker(firestoreConfig);

export default class {
    eventBus;
    userService;
    firestore;
    realtimeDigitalTwinsBalanceQueryUnsubscribe;
    // Remember to always use:
    // await this.userService.firebaseAuthPrecheck()
    // before calling anything
    // It will return immediately if the user is already logged in with it's token

    constructor(eventBus) {
        this.eventBus = eventBus;
        this.userService = userService;
        this.firestore = firestore
    }

    async getDigitalTwinsBalancesRealtime(callback) {
        if (!auth.currentUser) {
            await this.userService.firebaseLogin();
        }
        const facilityId = facilityService.chosenFacilityId;
        const q = query(
            collection(firestore, "digital-twins-owner-balances"), 
            where('ownerType', '==' ,'FACILITY'),
            where('ownerId', '==', facilityId)
        );

        this.realtimeDigitalTwinsBalanceQueryUnsubscribe = onSnapshot(q, querySnapshot => {
                const digitalTwinBalances = [];
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.numberOfDigitalTwins > 0) {
                        digitalTwinBalances.push(data)
                    }
                });
                callback(digitalTwinBalances);
            });
    }

    stopRealtimeUpdates() {
        if (this.realtimeDigitalTwinsBalanceQueryUnsubscribe) {
            this.realtimeDigitalTwinsBalanceQueryUnsubscribe()
        }
    }

}
