import {digitalTwinService, launchDarklyService} from "@/main";
import {LDFeatureFlags} from "@empower-platform/general-frontend-shared-feature-flags";
import cloneDeep from "lodash.clonedeep";
import { MaterialAmount, WEIGHT } from "@empower-platform/tracking-frontend-shared-types-js/build/materials/MaterialAmount";

export const initialDigitalTwinState = {
    inventory: [],
    hasLoadedDigitalTwins: false,
    isDigitalTwinEnabled: false,
};

export const digitalTwinStore = {
    state: {
        ...cloneDeep(initialDigitalTwinState)
    },
    actions: {
        async getIsDigitalTwinEnabled({commit}) {
            const isDigitalTwinEnabled = await launchDarklyService.isFlagEnabled(LDFeatureFlags.DIGITAL_TWINS);
            commit('setIsDigitalTwinEnabled', isDigitalTwinEnabled);
        },
        async getDigitalTwinsBalances({commit}) {
            await digitalTwinService.getDigitalTwinsBalancesRealtime((digitalTwins => {
                commit('setDigitalTwinInventory', digitalTwins);
            }));
        },
    },
    mutations: {
        setIsDigitalTwinEnabled(state, isDigitalTwinEnabled) {
            state.isDigitalTwinEnabled = isDigitalTwinEnabled;
        },
        setDigitalTwinInventory(state, digitalTwins) {
            state.inventory = [...digitalTwins];

            /**
             * TODO: encapsulate these transformations in a type. Letting the
             * type/class do whatever validation/transformation is necessary,
             * letting it encapsulate whatever type-specific logic is necessary,
             * and letting it add semantic meaning to the data. Something like:
             *     const _dts = digitalTwins.map(el => new DigitalTwin(el))
             */
            const _dts = digitalTwins.map(dt => {
                const hydratedInventory = {};
                Object.entries(dt.digitalTwinsInventory).forEach(entry => {
                    const [key, val] = entry;
                    
                    if (val.inventoryType == 'MASS_BALANCE') {
                        /**
                         * Legacy Digital Twins don't have a magnitude
                         * value stored in Firestore, and the frontend is
                         * fetching them directly from Firestore w/o any
                         * translation layer inbetween (ie: no backend service).
                         * 
                         * So it's fair to assume that all legacy Txs use
                         * WEIGHT magnitude.
                         */
                        const mag = val.magnitude || WEIGHT;
                        const amount = new MaterialAmount(
                            val.amount,
                            mag,
                            this.getters.preferredUnits,
                        )
                        const hydratedItem = {...val, amount}
                        hydratedInventory[key] = hydratedItem
                        return;
                    }

                    hydratedInventory[key] = val;
                });

                const hydratedDt = {...dt, digitalTwinsInventory: hydratedInventory}
                return hydratedDt;
            })
            state.inventory = _dts;
        },
        resetDigitalTwinStore(state) {
            Object.assign(state, cloneDeep(initialDigitalTwinState));
            digitalTwinService.stopRealtimeUpdates()
        }
    },
};
