import { MaterialAmount, WEIGHT, PreferredUnits } from './MaterialAmount';
/**
 * calculateProductionLoss: calculates Production Loss between a set
 * of input and output amounts.
 *
 * Production Loss is the delta of mass between inputs and outputs. So
 * it only makes sense when ALL inputs and ALL outputs are expressed
 * in mass units (weight units in practice). And it only makes sense
 * to be expressed in weight units.
 *
 * @param {Array<MaterialAmount>} inputAmounts
 * @param {Array<MaterialAmount>} outputAmounts
 * @param {PreferredUnits} prefUnits
 * @returns {MaterialAmount}
 */
export function calculateProductionLoss(inputAmounts, outputAmounts, prefUnits) {
    /**
     * If any of the inputs or outputs is not of type
     * MaterialAmount it doesn't make sense to calculate
     * Production Loss.
     */
    const nonMaterialAmountInputs = inputAmounts.filter(el => !(el instanceof MaterialAmount));
    const nonMaterialAmountOutputs = outputAmounts.filter(el => !(el instanceof MaterialAmount));
    const typesAreCorrect = (nonMaterialAmountInputs.length == 0)
        && (nonMaterialAmountOutputs.length == 0);
    if (!typesAreCorrect) {
        /**
         * Semantically, it'd be ideal to throw an exception
         * here. Something like:
         *
         *   throw new ProdLossError(
         *     `
         *     Can only calculate Production Loss between
         *     MaterialAmount types.
         *     `
         *   )
         *
         * But for simplicity we'll return zero Prod Loss for
         * the time being.
         */
        return new MaterialAmount(0, WEIGHT, prefUnits);
    }
    /**
     * If any of the inputs or outputs is not expressed in
     * weight units, it doesn't make sense to calculate
     * Production Loss. See discussion here
     * https://github.com/EmpowerPlastic/empower-platform/issues/1733#issuecomment-1048788433
     */
    const nonMassInputs = inputAmounts.filter(el => !WEIGHT.equals(el.magnitude));
    const nonMassOutputs = outputAmounts.filter(el => !WEIGHT.equals(el.magnitude));
    const canCalculateProdLoss = (nonMassInputs.length == 0)
        && (nonMassOutputs.length == 0);
    if (!canCalculateProdLoss) {
        /**
         * Semantically, it'd be ideal to throw an exception
         * here. Something like:
         *
         *   throw new ProdLossError(
         *     `
         *     Can only calculate Production Loss when  all
         *     the amounts are expressed in weight units.
         *     `
         *   )
         *
         * But for simplicity we'll return zero Prod Loss for
         * the time being.
         */
        return new MaterialAmount(0, WEIGHT, prefUnits);
    }
    let totalInputsMass = new MaterialAmount(0, WEIGHT, prefUnits);
    for (const input of inputAmounts) {
        totalInputsMass = input.plus(totalInputsMass);
    }
    let totalOutputsMass = new MaterialAmount(0, WEIGHT, prefUnits);
    for (const output of outputAmounts) {
        totalOutputsMass = output.plus(totalOutputsMass);
    }
    return totalInputsMass.minus(totalOutputsMass);
}
