<template>
    <div id="app">
        <Loader :is-visible="isLoading"></Loader>
        <router-view></router-view>
    </div>
</template>

<script>
import axios from 'axios'
import Loader from "./components/Loader";
import { serviceWorkerMixin } from '@empower-platform/general-frontend-shared-service-worker';

export default {
    name: 'app',
    components: {Loader},
    mixins: [ serviceWorkerMixin ],
    data() {
        return {
            isLoading: false,
            axiosInterceptor: null
        }
    },
    mounted() {
        this.$loadingService.registerActivateLoading(() => this.isLoading = true);
        this.$loadingService.registerDeactivateLoading(() => this.isLoading = false);
        this.enableInterceptor();
    },
    methods: {
        enableInterceptor() {
            this.axiosInterceptor = axios.interceptors.request.use((config) => {
                this.$loadingService.addLoadingItem();
                return config;
            }, (error) => {
                this.$loadingService.removeLoadingItem();

                let errorStr = error;
                if (error && error.response && error.response.data && error.response.data.message) {
                    errorStr = error.response.data.message;
                }
                this.$eventBus.$emit("error", errorStr);
                return Promise.reject(error);
            });

            axios.interceptors.response.use((response) => {
                this.$loadingService.removeLoadingItem();
                return response;
            }, (error) => {
                this.$loadingService.removeLoadingItem();
                let errorStr = error;
                if (error && error.response && error.response.data && error.response.data.message) {
                    errorStr = error.response.data.message;
                }
                this.$eventBus.$emit("error", errorStr);
                return Promise.reject(error);
            });
        },

        disableInterceptor() {
            axios.interceptors.request.eject(this.axiosInterceptor)
        },
    },
}
</script>


<style lang="scss">
    @import "variables";
/* http://meyerweb.com/eric/tools/css/reset/
 v2.0 | 20110126
 License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

html {
    height: unset !important;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

#app {
    font-family: 'Open Sans', sans-serif;
    color: #1B1B1B;
    @media only screen and (min-width: 1023px) {
        min-height: 100vh;
    }
    
}

.fade-custom-enter-active,
.fade-custom-leave-active {
    transition: opacity 0.5s;
}

.fade-custom-enter,
.fade-custom-leave-to {
    opacity: 0;
}

textarea {
    font-family: 'Open Sans', sans-serif !important;
}

::selection {
    background: $main-green !important; /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: $main-green !important; /* Gecko Browsers */
}

</style>
