import { Transaction } from './';
import { DigitalTwins, MassBalance, Material, SelectedMaterialAttribute } from "../materials/Material";
import { Magnitude, MaterialAmount } from "../materials/MaterialAmount";
/**
 * Transforms a raw API response for multiple transactions into an array of semantically meaningful transactions.
 *
 * @param {Object[]} txsFromApi - Array of raw transaction data from API.
 * @param {PreferredUnits} preferredUnits - User's preferred units for representation.
 * @returns {Transaction[]} An array of transformed transactions.
 */
export const mapTxsFromApi = (txsFromApi, preferredUnits) => {
    try {
        if (!txsFromApi || txsFromApi.length === 0) {
            return [];
        }
        return txsFromApi.map(tx => {
            return Transaction.fromApiResponse(tx, preferredUnits);
        });
    }
    catch (e) {
        console.log(e);
        throw new Error(e);
    }
};
