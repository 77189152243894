const { DEFAULT_PREFERRED_UNITS, PreferredUnits } = require("@empower-platform/tracking-frontend-shared-types-js/build/materials/MaterialAmount")

export class Organization {
    preferredUnits = null

    constructor(preferredUnits=DEFAULT_PREFERRED_UNITS) {

        this.preferredUnits = preferredUnits;
    }

    static fromJson({preferredUnits}) {
        return new Organization(
            PreferredUnits.fromJson(preferredUnits)
        );
    }
}
