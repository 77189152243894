export default class {

    vue;
    eventBus;

    constructor(vue, eventBus) {
        this.vue = vue;
        this.eventBus = eventBus;
        this.eventBus.$on('error', (errStr) => {
            this.handleError(errStr);
        });
    }

    handleError(errStr) {
        // TODO: Log the error with Sentry?
        // Show the user something useful
        this.vue.$toasted.error(errStr);
    }

}
