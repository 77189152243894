export class State extends String {
    constructor() {
        super(...arguments);
        this.equals = (other) => !!other && other.toString() === this.toString();
    }
}
export const INITIATED = new State('INITIATED');
export const REQUESTED = new State('REQUESTED');
export const CANCELED = new State('CANCELED');
export const CANCELLED = new State('CANCELLED'); // Don't kill the messenger. Server sends CANCELED for txState, CANCELLED for discrepancyState
export const REJECTED = new State('REJECTED');
export const ACCEPTED = new State('ACCEPTED');
export const COMPLETED = new State('COMPLETED');
export const REVERTED = new State('REVERTED');
export class States extends Array {
    constructor() {
        super(...arguments);
        this.isValid = (elem) => {
            return this.some(el => el.equals(elem));
        };
        this.get = (elem) => {
            return this.find(el => el.equals(elem));
        };
    }
}
export const STATES = new States(INITIATED, CANCELED, CANCELLED, REJECTED, ACCEPTED, COMPLETED, REVERTED);
