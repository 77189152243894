import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';
import imageCompression from 'browser-image-compression';

export default class SharedFileUploadService {
    constructor() {
        this.initializeUppy();
        this.setListenToUppyComplete();
        this.setListenToUppyProgress();
        this.setListenToUppyErrors();
        this.results = undefined;
        this.progress = undefined;
    }

    initializeUppy() {
        this.uppy = new Uppy({
            debug: true,
            autoProceed: false,
            restrictions: {
                maxFileSize: 104857600 // 100 MB
            }
        })

        this.uppy.use(Transloadit, {
            params: {
                auth: {key: '1b664df28abb4d27a4d8d9a3485b6d33'},
                template_id: 'fc12621618824a59bbcacfc55e3479dc'
            },
            waitForEncoding: true,
            limit: 1
        })
    }

    async uploadFiles(files) {
        if (!files) return Promise.resolve();
        const filesReadyForUpload = await this.compressImages(files);
        for (let file of filesReadyForUpload) {
            this.uppy.addFile({
                name: file.name,
                type: file.type,
                data: file,
            })
        }
        await this.uppy.upload()
        const response = this.formatResponse()
        this.clearUploadData();
        return response;
    }

    async uploadVideo(video) {
      if (!video) return Promise.resolve();
      this.uppy.addFile({
        name: video.name,
        type: video.type,
        data: video
      });
      await this.uppy.upload();
      let response = null;
      if (this.results.other_files_filter && this.results.other_files_filter.length > 0) {
        const videoFile = this.results.other_files_filter[0];
        response = {
          type: videoFile.type,
          url: videoFile.ssl_url,
          name: videoFile.name,
          size: videoFile.size
        }
      }
      this.clearUploadData();
      return response;
    }
    
    deleteFile(file) {
        const existingFile = this.uppy.getFiles().find(f => f.name === file.title);
        if(!!existingFile) {
            this.uppy.removeFile(existingFile.id);
        }
    }

    async compressImages(files) {
        let filesReadyForUpload = [];
        for (let file of files) {
            if (this.isStaticImage(file)) {
                file = await this.compressImage(file)
            }
            filesReadyForUpload.push(file);
        }
        return filesReadyForUpload
    }

    async compressImage(imageFile) {
        const options = {maxSizeMB: .25, initialQuality: 0.5, maxIteration: 10}
        try {
            return await imageCompression(imageFile, options)
        } catch (err) {
            return err;
        }
    }

    formatResponse() {
        const results = this.results;
        let response = [];
        if (results.compress_image) {
            response = [...results.compress_image]
        }
        if (results.other_files_filter) {
            response = [...response, ...results.other_files_filter]
        }
        return response;
    }

    clearUploadData() {
        this.uppy.cancelAll();
        this.results = undefined;
        this.progress = undefined;
    }

    isStaticImage(file) {
        return file && file['type'].split('/')[0] === 'image' && file['type'].split('/')[1] !== 'gif';
    }

    setListenToUppyComplete() {
        this.uppy.on('transloadit:complete', (assembly) => {
            this.results = assembly.results
        })
    }

    setListenToUppyProgress() {
        this.uppy.on('progress', (progress) => {
            this.progress = progress;
        })
    }

    setListenToUppyErrors() {
        this.uppy.on('error', (error) => {
            this.uppy.cancelAll();
        })

        this.uppy.on('upload-error', (file, error, response) => {
            this.uppy.cancelAll();
        })
    }
}
