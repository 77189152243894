import axios from 'axios'
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { apiService } from "@/main";
import { firestoreInvoker, signInWithCustomToken } from "@empower-platform/general-frontend-shared-firebase-setup";
import RollbarSetup from "@empower-platform/general-frontend-shared-rollbar-setup";

const firestoreConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    firebaseEmulator: process.env.VUE_APP_FIREBASE_EMULATOR
}

const { auth } = firestoreInvoker(firestoreConfig);

export default class {
    eventBus;
    token;
    userId;
    userInfo;
    organizationId;
    rollbarConfig;

    constructor(eventBus, rollbarConfig) {
        this.eventBus = eventBus;
        this.rollbarConfig = rollbarConfig;
        this.token = null;
        this.userId = null;
        this.userInfo = new Promise((resolve, reject) => {
            this.userInfoResolve = resolve;
            this.userInfoReject = reject;
        })
    }

    async initUserService() {
        const token = Cookies.get("token") || localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));
        const userId = Cookies.get("userId") || localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId"));
        if (token && userId) {
            this.token = token;
            this.userId = userId;

            return this.updateUserInfo().catch(async () => {
                this.eventBus.$emit('error', "Something went wrong while fetching your credentials");
                await this.logout()
            })
        }
    }

    login(password, email) {
        this.eventBus.$emit('loggedOut');
        return axios.post(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/login", {
            email,
            password
        }).then(response => {
            this.token = response.data.token;
            this.userId = response.data.userId;
            this.setToken(this.token);
            this.setUserId(this.userId);
            apiService.setToken()

            return this.updateUserInfo();
        })
    }

    firebaseLogin() {
        return axios.post(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/firebase-login", {}, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            }
        )
            .then(response => signInWithCustomToken(auth, response.data.firebaseCustomToken))
    }

    getToken() {
        return this.token;
    }

    setToken(token) {
        Cookies.set("token", token);
        localStorage.setItem("token", JSON.stringify(token));
    }

    clearToken() {
        Cookies.remove("token");
        localStorage.removeItem("token");
    }

    setUserId(userId) {
        Cookies.set("userId", userId);
        localStorage.setItem("userId", JSON.stringify(userId));
    }

    clearUserId() {
        Cookies.remove("userId");
        localStorage.removeItem("userId");
    }

    isLoggedIn() {
        return !!this.token;
    }

    async logout() {
        this.token = null;
        this.userId = null;
        this.userinfo = null;
        await auth.signOut();
        this.clearToken();
        this.clearUserId();
        this.eventBus.$emit('loggedOut');
        if (window.analytics) {
            window.analytics.reset();
        }
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    }

    updateUserInfo() {
        const promises = []
        promises.push(this.firebaseLogin())

        promises.push(axios.get(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/" + this.userId, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            }
        ).then(response => {
            const userInfo = response.data;
            this.userInfoResolve(userInfo);
            this.userInfo = Promise.resolve(userInfo);
            const organizationId = userInfo.organization ? userInfo.organization.organizationId : undefined
            const isDelterraPilot = userInfo.organization ? userInfo.organization.isDelterraPilot : undefined
            this.organizationId = organizationId;
            this.rollbarConfig.configurePerson(
                this.userId,
                userInfo.email,
                process.env.VUE_APP_EMPOWER_ENVIRONMENT
            )
            this.eventBus.$emit('userChanged', {
                id: this.userId,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                email: userInfo.email,
                organizationId,
                isDelterraPilot
            });

            if (window.analytics) {
                const userOrganization = userInfo.organization;
                const rollbar = RollbarSetup.getRollbarInstance()
                if (!userOrganization) {
                    rollbar.error("No org found for user", userInfo)
                } else if (!userOrganization.organizationId) {
                    rollbar.error("No orgId found for user", userInfo)
                } else if (!userOrganization.orgName) {
                    rollbar.error("No orgName found for user", userInfo)
                }
                const showIntercom = this._getBrowserWidth() > 1019;
                const userRoles = this.getUserRoles();
                const mainUserRole = userRoles && userRoles.length > 0 ? userRoles[0].roleName : undefined;
                window.analytics.identify(this.userId, {
                    email: userInfo.email,
                    name: userInfo.firstName,
                    mainUserRole: mainUserRole,
                    empowerEnvironment: process.env.VUE_APP_EMPOWER_ENVIRONMENT,
                    orgId: userOrganization ? userOrganization.organizationId : undefined,
                    orgName: userOrganization ? userOrganization.orgName : undefined,
                    phoneNumber: userInfo.phoneNumber
                }, {
                    Intercom: { hideDefaultLauncher: !showIntercom }
                });
                if (userOrganization) {
                    window.analytics.group(userOrganization.organizationId, {
                        name: userOrganization.orgName,
                        /** Does not follow naming convention, but remains due to unknown use in Intercom **/
                        org_empower_environment: process.env.VUE_APP_EMPOWER_ENVIRONMENT,
                    });
                }
            }
        }));

        return Promise.all(promises)
    }

    _getBrowserWidth() {
        return Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth
        );
      }

    updateUserPassword(oldPassword, newPassword) {
        return axios.post(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/" + this.userId + "/changePassword", {
                userId: this.userId,
                oldPassword: oldPassword,
                newPassword: newPassword
            }, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            }
        )
    }


    updateUser(userUpdateObj) {
        return axios.put(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/" + this.userId,
            {
                email: userUpdateObj.email,
                firstName: userUpdateObj.firstName,
                lastName: userUpdateObj.lastName,
                phoneNumber: userUpdateObj.phoneNumber,
                profilePictureUrl: userUpdateObj.profilePictureUrl,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            }
        )
    }

    resetPassword(resetCode, newPassword) {
      return axios.put(process.env.VUE_APP_USER_API_BASE_URL + '/api/v1/user/users/updatePassword', {
        password: newPassword,
        otp: resetCode
      });
    }

    sendResetPasswordLink(email) {
        return axios.post(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/resetPassword", {
            email,
            application: "tracking"
        });
    }

    getUserInfo() {
        return this.userInfo;
    }

    isPartOfOrganization() {
        return this.userInfo.organization
    }

    getOrganizationId() {
        return this.userInfo.then(userInfo => {
            return userInfo.organization ? userInfo.organization.organizationId : null;
        });
    }

    async getUserNameFromId(userId) {
        const response = await axios.get(process.env.VUE_APP_USER_API_BASE_URL + "/api/v1/user/users/" + userId, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            }
        )
        return `${response.data.firstName} ${response.data.lastName}`;
    }

    getUserRoles() {
        const roles = [];
        try {
            const decodedToken = jwt_decode(this.token);
            const roleStrings = decodedToken.roles;
            for (const roleStr of roleStrings) {
                const splitArray = roleStr.split("~");
                if (splitArray.length === 3) {
                    roles.push({
                        entityId: splitArray[0],
                        entityType: splitArray[1],
                        roleName: splitArray[2]
                    })
                }
            }
        } catch (err) {
            console.log(err);
        }
        return roles;
    }
}
