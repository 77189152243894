import * as LDClient from "launchdarkly-js-client-sdk";

const LDFeatureFlags = {
    DIGITAL_TWINS: "digital-twins",
    PRODUCT_PASSPORTS: "product-passports",
    DELTERRA_PILOT_MEMBERSHIP: "delterra-pilot-membership",
    PRODUCT_PASSPORT_BUILDER: "product-passport-builder",
    SHOW_REWARDS: "rewards",
    NEW_PLASTIC_CREDITS: "new-plastic-credits",
    PLASTIC_CREDIT_LISTING: "plastic-credit-listing",
}

class LaunchDarklyService {
    constructor(eventBus, launchDarklyClientKey) {
        this._eventBus = eventBus;

        this._eventBus.$on("userChanged", (empUser) => {
            const loggedInUser = {
                key: empUser.id,
                firstName: empUser.firstName,
                lastName: empUser.lastName,
                email: empUser.email,
                custom: {
                    organizationId: empUser.organizationId,
                    isDelterraPilot: empUser.isDelterraPilot
                }
            }
            if (!this.lDClient) {
                this.lDClient = LDClient.initialize(launchDarklyClientKey, loggedInUser);
            } else {
                this.lDClient.identify(loggedInUser);
            }
        });
        this._eventBus.$on("loggedOut", () => {
            if (this.lDClient) {
                this.lDClient.identify({
                    anonymous: true
                });
            }
        });
    }

    async isFlagEnabled(flag) {
        try {
            await this.lDClient.waitForInitialization();
        } catch (err) {
            console.log("Feature flag service is down");
            return false;
        }

        return this.lDClient.variation(flag, false);
    }
}

export { LDFeatureFlags, LaunchDarklyService };

