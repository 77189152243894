import { wasteService } from '../main';
import { Pagination } from '@empower-platform/tracking-frontend-shared-types-js/build/pagination/Pagination'
import cloneDeep from "lodash.clonedeep";
import {mapTxsFromApi} from "@empower-platform/tracking-frontend-shared-types-js/build/transactions";

export const initialHistoryState = {
    txs: [],
    shouldUpdateHistory: true,  
    loadingHistory: false,
    pagination: new Pagination(),
 }

export const HistoryStore = {
    state: {
        ...initialHistoryState,
    },
    actions: {
        async getHistory({state, commit}) {
            try {
                commit('loadingHistory', true);
                const queryParams = state.pagination.getQueryParams()
                const filters = state.pagination.getFilters()
                const paginatedTxs = await wasteService.getFacilityHistory(queryParams)
                const opaqueTxs = [...paginatedTxs.transactions];
                const mappedTransactions = mapTxsFromApi(opaqueTxs, this.getters.preferredUnits)
                commit('setTxs', mappedTransactions);
                commit('setPagination', paginatedTxs.paginationInfo)
            } catch (e) {
                throw new Error('Could not load history. Please try again');
            } finally {
                commit('loadingHistory', false);
            }
        },
    },
    mutations: {
        loadingHistory(state, status = false) {
            state.loadingHistory = status;
        },

        setTxs(state, transactions) {
            state.shouldUpdateHistory = false;
            state.txs = transactions;
        },
        
        setPagination(state, pagination) {
            state.pagination.setPagination(pagination);
        },

        /**
         * @param state // vuex state
         * @param {Transaction} transaction
         */
        addHistoryDeliveryRequest(state, transaction) {
             const entryExists = state.txs.findIndex(tx => {
                 return tx.id === transaction.id ||
                     (tx.request?.code && tx.request?.code === transaction.request?.code);
             });
             if (entryExists > -1) {
                 state.txs.splice(entryExists, 1, transaction);
             } else {
                 state.txs.push(transaction);
             }
        },

        /**
         * @param state // vuex state
         * @param {Transaction} transaction
         */
        addHistoryEntry(state, transaction) {
            const index = state.txs.findIndex(tx => tx.id === transaction.id)
            if(index > -1) {
                state.txs.splice(index, 1, transaction)
            } else {
                state.txs.push(transaction)
            }
        },
        
        resetHistoryStore(state) {
            Object.assign(state, cloneDeep(initialHistoryState));
        }
    }
}
