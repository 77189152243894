import Vue from 'vue';
import VueRouter from 'vue-router';
import { reloadSite } from '@empower-platform/general-frontend-shared-service-worker';

const START_ROUTE_NAME = "start";
const MY_PAGE_ROUTE_NAME = "my-page";

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
          name: START_ROUTE_NAME,
          path: `/${START_ROUTE_NAME}`,
          component: () => import(/* webpackChunkName: "LoggedOutLayout", webpackPrefetch: true */ "@/layouts/LoggedOut"),
          meta: {
              requiresLogin: false,
          },
          children: [
              {
                  path: '/location-permission',
                  name: 'location-permission',
                  meta: {
                    inFlow: true,
                  },
                  component: () => import(/* webpackChunkName: "Start" */ "@/screens/LocationPermission"),
                  props: true,
              },
              {
                  path: '/select-facility',
                  name: 'select-facility',
                  meta: {
                    inFlow: true,
                  },
                  component: () => import(/* webpackChunkName: "Start" */ "@/screens/SelectFacility"),
                  props: true,
              },
              {
                  path: '/sign-up',
                  name: 'sign-up',
                  meta: {
                    inFlow: true,
                  },
                  component: () => import(/* webpackChunkName: "SignUpScreen" */ "./screens/SignUpScreen"),
              },
              {
                  path: '/start',
                  name: 'start',
                  component: () => import(/* webpackChunkName: "Start" */ "./screens/StartScreen"),
              },
              {
                  path: '/forgot-password',
                  name: 'forgot-password',
                  component: () => import(/* webpackChunkName: "ForgotPassword" */ "./screens/password/ForgotPassword"),
                  props: true
              },
              {
                  path: '/reset-password',
                  name: 'reset-password',
                  meta: {
                    inFlow: true,
                  },
                  component: () => import(/* webpackChunkName: "ResetPassword" */ "@/screens/password/ResetPassword"),
              },
              {
                  path: '/change-password-confirmation',
                  name: 'change-password-confirmation',
                  component: () => import(/* webpackChunkName: "ChangePasswordConfirmation" */ "@/screens/password/ChangePasswordConfirmation"),
              },
              {
                  path: '/reset-password-success',
                  name: 'reset-password-success',
                  component: () => import(/* webpackChunkName: "ResetPasswordSuccess" */ "@/screens/password/ResetPasswordSuccess"),
              },
          ]
        },
        {
            path: '/',
            name: 'app',
            component: () => import(/* webpackChunkName: "AppLayout", webpackPrefetch: true */ "@/layouts/AppLayout"),
            redirect: '/services',
            meta: {
                requiresLogin: true,
                requiresFacilityChosen: true
            },
            children: [
                {
                    name: 'home',
                    path: 'services',
                    component: () => import(/* webpackChunkName: "Home", webpackPrefetch: true */ "@/screens/Home"),
                    props: true,
                },
                {
                    name: 'history-page',
                    path: 'history-page',
                    component: () => import(/* webpackChunkName: "HistoryComponent" */ "@/screens/History"),
                },
                {
                    name: MY_PAGE_ROUTE_NAME,
                    path: MY_PAGE_ROUTE_NAME,
                    component: () => import(/* webpackChunkName: "MyPageComponent" */ "./screens/MyPage"),
                },
                {
                    name: 'my-profile',
                    path: 'my-profile',
                    component: () => import(/* webpackChunkName: "MyProfile" */ "./screens/MyProfile"),
                },
                {
                    path: '/change-password',
                    name: 'change-password',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "ChangePassword" */ "@/screens/password/ChangePassword"),
                },
                {
                    path: '/select-inputs',
                    name: 'select-inputs',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Process" */ "./screens/process/SelectInputs"),
                    props: true
                },
                {
                    path: '/select-outputs',
                    name: 'select-outputs',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Process" */ "./screens/process/SelectOutputs"),
                    props: true
                },
                {
                    path: '/process-overview',
                    name: 'process-overview',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Process" */ "./screens/process/ProcessOverview"),
                    props: true
                },
                {
                    path: '/process-confirmation',
                    name: 'process-confirmation',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Process" */ "@/screens/process/ProcessConfirmation"),
                    props: true
                },
                {
                    path: '/type',
                    name: 'set-delivery-type',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/_shared/01_SetType"),
                    props: true
                },
                {
                    path: '/material',
                    name: 'set-delivery-material',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/_shared/02_SetMaterial"),
                    props: true
                },
                {
                    path: '/amount',
                    name: 'set-delivery-amount',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/_shared/03_SetAmount"),
                    props: true
                },
                {
                    path: '/payment',
                    name: 'set-delivery-payment',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/_shared/04_SetPayment"),
                    props: true
                },
                {
                    path: '/materials',
                    name: 'delivery-materials',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/_shared/05_MaterialList"),
                    props: true
                },
                {
                    path: '/recipient',
                    name: 'set-delivery-recipient',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/_shared/06_SetRecipient"),
                    props: true
                },
                {
                    path: '/overview',
                    name: 'delivery-overview-unconfirmed',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/unconfirmed/07_OverviewBeforeSend"),
                    props: true
                },
                {
                    path: '/overview',
                    name: 'delivery-overview-remote',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/remote/07_OverviewBeforeSend"),
                    props: true
                },
                {
                    path: '/overview',
                    name: 'delivery-overview-onsite',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/onsite/07_OverviewBeforeSend"),
                    props: true
                },
                {
                    path: '/confirmation',
                    name: 'delivery-confirmation-unconfirmed',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/unconfirmed/08_Confirmation"),
                    props: true
                },
                {
                    path: '/confirmation',
                    name: 'delivery-confirmation-remote',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/remote/08_Confirmation"),
                    props: true
                },
                {
                    path: '/confirmation',
                    name: 'delivery-confirmation-onsite',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/onsite/09_Confirmation"),
                    props: true
                },
                {
                    path: '/overview',
                    name: 'delivery-overview-after-send',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/_shared/OverviewAfterSend"),
                    props: true
                },
                {
                    path: '/delivery-code',
                    name: 'delivery-code',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Delivery" */ "./screens/delivery/onsite/08_DeliveryCode"),
                    props: true
                },
                {
                    path: '/scan-delivery',
                    name: 'scan-delivery-qr',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "QrScanDelivery" */ "./screens/delivery/qr_scan/01_ScanQr"),
                    props: true
                },
                {
                    path: '/material',
                    name: 'set-delivery-material-qr-scan',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "QrScanDelivery" */ "./screens/delivery/qr_scan/02_SetMaterial"),
                    props: true
                },
                {
                    path: '/payment',
                    name: 'set-delivery-payment-qr-scan',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "QrScanDelivery" */ "./screens/delivery/qr_scan/03_SetPayment"),
                    props: true
                },
                {
                    path: '/materials',
                    name: 'delivery-materials-qr-scan',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "QrScanDelivery" */ "./screens/delivery/qr_scan/04_MaterialList"),
                    props: true
                },
                {
                    path: '/recipient',
                    name: 'set-delivery-recipient-qr-scan',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "QrScanDelivery" */ "./screens/delivery/qr_scan/05_SetRecipient"),
                    props: true
                },
                {
                    path: '/overview',
                    name: 'delivery-overview-qr-scan',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "QrScanDelivery" */ "./screens/delivery/qr_scan/06_OverviewBeforeSend"),
                    props: true
                },
                {
                    path: '/delivery-requests',
                    name: 'delivery-requests',
                    component: () => import(/* webpackChunkName: "DeliveryRequests" */ "./screens/DeliveryRequests"),
                    props: true
                },
                {
                    path: '/review-change-request',
                    name: 'review-change-request',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Deliver" */ "./screens/delivery/remote/discrepancy/ReviewChangeRequest.vue"),
                    props: true
                },
                {
                    path: '/receive',
                    name: 'receive',
                    component: () => import(/* webpackChunkName: "Receive" */ "@/screens/receive/_shared/00_SetType"),

                },
                {
                    path: '/receive-scan',
                    name: 'receive-scan',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/onsite/01A_ScanQrCode"),
                    props: true
                },
                {
                    path: '/receive-type-code',
                    name: 'receive-type-code',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/onsite/01B_TypeQRCode"),
                    props: true
                },
                {
                    path: '/receive-overview',
                    name: 'receive-overview-onsite',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "@/screens/receive/onsite/02_OverviewBeforeAccept"),
                    props: true
                },
                {
                    path: '/receiver-request-change',
                    name: 'receiver-request-change',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/remote/discrepancy/01_RequestChange.vue"),
                    props: true
                },
                {
                    path: '/edit-material-amount',
                    name: 'edit-material-amount',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/remote/discrepancy/02_EditAmount.vue"),
                    props: true
                },
                {
                    path: 'confirm-change-request-sent',
                    name: 'confirm-change-request-sent',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/remote/discrepancy/03_ConfirmChangeRequestSent.vue"),
                    props: true
                },
                {
                    path: 'reason-for-change',
                    name: 'reason-for-change',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Deliver" */ "./screens/delivery/remote/discrepancy/ReasonForChange.vue"),
                    props: true
                },
                {
                    path: 'confirm-change-request-reviewed',
                    name: 'confirm-change-request-reviewed',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Deliver" */ "./screens/delivery/remote/discrepancy/Confirmation.vue"),
                    props: true
                },
                {
                    path: '/receive-overview',
                    name: 'receive-overview-remote',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/remote/01_OverviewBeforeAccept"),
                    props: true
                },
                {
                    path: '/receive-convert-unit',
                    name: 'receive-convert-unit',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/remote/02_ConvertUnit"),
                    props: true
                },
                {
                    path: '/receive-confirmation',
                    name: 'receive-confirmation-two-party',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/_shared/TwoPartyConfirmation"),
                    props: true
                },
                {
                    path: '/receive-from',
                    name: 'receive-set-from',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/unconfirmed/01_SetReceiveFrom"),
                    props: true
                },
                {
                    path: '/receive-set-material',
                    name: 'receive-set-material',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/unconfirmed/02_SetMaterial"),
                    props: true
                },
                {
                    path: '/receive-set-amount',
                    name: 'receive-set-amount',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/unconfirmed/03_SetAmount"),
                    props: true
                },
                {
                    path: '/receive-set-payment',
                    name: 'receive-set-payment',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/unconfirmed/04_SetPayment"),
                    props: true
                },
                {
                    path: '/receive-materials',
                    name: 'receive-materials',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/unconfirmed/05_MaterialList"),
                    props: true
                },
                {
                    path: '/receive-overview',
                    name: 'receive-overview-unconfirmed',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/unconfirmed/06_Overview"),
                    props: true
                },
                {
                    path: '/receive-confirmation',
                    name: 'receive-confirmation-unconfirmed',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/unconfirmed/07_Confirmation"),
                    props: true
                },
                {
                    path: '/receive-overview',
                    name: 'receive-overview-after-accept',
                    meta: {
                        inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "Receive" */ "./screens/receive/_shared/OverviewAfterAccept"),
                    props: true
                },
                {
                    path: '/cp-receive-from',
                    name: 'cp-receive-from',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "CollectionPointReceive" */ "./screens/collectionPointMode/NotVerified/ReceiveFrom"),
                    props: true
                },
                {
                    path: '/cp-receive-type',
                    name: 'cp-receive-type',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "CollectionPointReceive" */ "./screens/collectionPointMode/NotVerified/ReceiveMaterial"),
                    props: true
                },
                {
                    path: '/cp-receive-amount',
                    name: 'cp-receive-amount',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "CollectionPointReceive" */ "./screens/collectionPointMode/NotVerified/ReceiveAmount"),
                    props: true
                },
                {
                    path: '/cp-overview',
                    name: 'cp-overview',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "CollectionPointReceive" */ "./screens/collectionPointMode/Overview"),
                    props: true
                },
                {
                    path: '/cpm-confirmation',
                    name: 'cpm-confirmation',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "CollectionPointReceive" */ "./screens/collectionPointMode/CpmConfirmation"),
                    props: true
                },
                {
                    path: '/upload-files',
                    name: 'upload-files',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "CollectionPointReceive" */ "./screens/collectionPointMode/UploadFiles"),
                    props: true
                },
                {
                    path: '/payment',
                    name: 'payment',
                    meta: {
                      inFlow: true,
                    },
                    component: () => import(/* webpackChunkName: "CollectionPointReceive" */ "./screens/collectionPointMode/Payment"),
                    props: true
                },
                {
                    path: '/inventory',
                    name: 'inventory',
                    component: () => import(/* webpackChunkName: "Inventory" */ "@/screens/Inventory"), 
                },
                {
                    path: '/qr-landing',
                    component: () => import(/* webpackChunkName: "Inventory" */ "@/screens/QrLanding"),
                }
            ]
        },
        {path: '/*', redirect: '/'},
    ]
});

router.onError(error => {
 if (/loading chunk \w* failed/i.test(error.message)) {
    reloadSite();
  }
});

router.beforeEach((to, from, next) => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
    if (to.matched.some(m => m.meta.requiresLogin)) {
        if (router.app.$userService.isLoggedIn()) {
            if (to.matched.some(m => m.meta.requiresFacilityChosen)) {
                router.app.$userService.isPartOfOrganization();
                if (router.app.$facilityService.hasSelectedFacility()) {
                    next();
                } else {
                    next({
                        path: "/select-facility",
                        query: {...to.query, "destination": to.path},
                    })
                }
            } else {
                next();
            }
        } else {
            next({
                    path: '/start',
                    query: {...to.query, "destination": to.path},
                });
        }
    } else {
        next();
    }
});

let showingWeglot = true;

router.afterEach(to => {
  let newDisplayStyle;

  if (showingWeglot && to.name !== START_ROUTE_NAME && to.name !== MY_PAGE_ROUTE_NAME) {
    newDisplayStyle = 'none';
  } else if (!showingWeglot && (to.name === START_ROUTE_NAME || to.name === MY_PAGE_ROUTE_NAME)) {
    newDisplayStyle = 'block';
  }

  if (newDisplayStyle && window.Weglot) {
    const weglotContainerQuery = document.getElementsByClassName("weglot-container");
    if (weglotContainerQuery.length === 1) {
      weglotContainerQuery[0].style.display = newDisplayStyle;
      showingWeglot = !showingWeglot;
    }
  }
});


export default router;
