/** Transaction Request **/
export class Request {
    /**
       * @param {Object} standardData
       */
    constructor(standardData) {
        this.type = standardData.type;
        this.code = standardData.code;
        this.target = new Target(standardData.target);
        this.rejectReason = standardData.rejectReason ? standardData.rejectReason : undefined;
        this.cancelReason = standardData.cancelReason ? standardData.cancelReason : undefined;
        this.discrepancyState = standardData.discrepancyState ? standardData.discrepancyState : undefined;
    }
    /**
     * @param {Object} responseObj
     */
    static fromApiResponse(responseObj) {
        const validatedRequestData = {
            type: responseObj.requestType,
            code: responseObj.requestCode,
            target: Target.fromApiResponse(responseObj)
        };
        if (this.hasRejectReason(responseObj)) {
            validatedRequestData.rejectReason = responseObj.rejectReason;
        }
        if (this.hasCancelReason(responseObj)) {
            validatedRequestData.cancelReason = responseObj.cancelReason;
        }
        if (this.hasDiscrepancyState(responseObj)) {
            validatedRequestData.discrepancyState = responseObj.discrepancyState;
        }
        return new Request(validatedRequestData);
    }
    static hasDiscrepancyState(requestObj) {
        return !!(requestObj.discrepancyState);
    }
    /**
       * @param {Object} requestObj
       * @returns {Boolean}
       **/
    static hasRejectReason(requestObj) {
        return !!(requestObj.rejectReason);
    }
    /**
       * @param {Object} requestObj
       * @returns {Boolean}
       **/
    static hasCancelReason(requestObj) {
        return !!(requestObj.cancelReason);
    }
}
class Target {
    constructor(standardData) {
        this.orgId = standardData.orgId;
        this.orgName = standardData.orgName;
        this.facilityId = standardData.facilityId;
        this.facilityName = standardData.facilityName;
    }
    static fromApiResponse(responseObj) {
        const validatedTargetData = {
            orgId: responseObj.targetedOrganizationId,
            orgName: responseObj.targetedOrganizationName,
            facilityId: responseObj.targetedFacilityId,
            facilityName: responseObj.targetedFacilityName
        };
        return new Target(validatedTargetData);
    }
}
