import { register } from 'register-service-worker';

function logger(message) {
  console.log(message);
}

const updateEvent = new CustomEvent("sw-version-change");

export const checkForNewAppRelease = () => {
  if ('serviceWorker' in navigator && window.isSecureContext && !navigator.userAgent.includes('Cypress')) {
    navigator.serviceWorker.getRegistration().then(worker => {
      if (worker) {
        worker.update().then(() => {
          logger('Checking for updates');
        });
      }
    })
  }
}

export function reloadSite() {
    // if page was refreshed less than 60 seconds ago, then skip refreshing.
    const lastUpdated = localStorage.getItem('reloads');
    if (!lastUpdated) {
      localStorage.setItem('reloads', JSON.stringify({updatedAt: Date.now(), updatedCount: 1}));
      location.reload();
    } else {
      const { updatedAt, updatedCount } = JSON.parse(lastUpdated);
      const currentTime = Date.now();
      if (currentTime > (updatedAt + (60 * 1 * 1000))) {
        localStorage.setItem('reloads', JSON.stringify({updatedAt: currentTime, updatedCount: updatedCount + 1}));
        location.reload(true);
      }
    }
}

let updateInterval; 

const setWorkerOnUpdateFunc = worker => {
  worker.onupdatefound = () => {
    logger('Found update. Reloading site.');
    if (updateInterval) {
      clearInterval(updateInterval);
    }
    window.dispatchEvent(updateEvent);
  }
}

const setWorkerUpdateIntervalFunc = worker => {
  const refreshTime = 60 * 1 * 1000; // check for new version every minute
  updateInterval = setInterval(() => {
    worker.update().then(() => {
      logger('Checking for updates');
    });
  }, refreshTime);
}

export const registerServiceWorker = (isProduction, baseUrl) => {
  if (navigator.userAgent.includes('Cypress')) {
    logger('Cypress agent, service worker not registered.');
    return;
  }

  if (isProduction) {
    logger('Running in development, service worker not registered');
    return;
  }
  
  if ('serviceWorker' in navigator && window.isSecureContext) {
    register(`${baseUrl}service-worker.js`, {
      ready() {
        logger('Service worker ready');
        navigator.serviceWorker.getRegistration().then(worker => {
          if (worker) {
              setWorkerOnUpdateFunc(worker);
              setWorkerUpdateIntervalFunc(worker);
          }
        });
      },
      registered() {
        logger('Service worker has been registered.');
      },
      cached() {
        logger('Content has been cached for offline use.');
      },
      updatefound() {
        logger('New content is downloading.')
      },
      updated() {
        logger('New content downloaded.');
      },
      offline() {
        logger('No internet connection found. App is running in offline mode.');
      },
      error(error) {
        logger('Error during service worker registration');
        throw error;
      }
    });
  }
}

    

