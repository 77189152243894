

import { initializeApp } from "firebase/app"
import { getFirestore, collection, query, where, doc, onSnapshot, getDocs, limit } from "firebase/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const firebaseConfig = (firebaseOptions) => {
    return {
        apiKey: firebaseOptions.apiKey,
        authDomain: firebaseOptions.authDomain,
        projectId: firebaseOptions.projectId,
        storageBucket: firebaseOptions.storageBucket,
        messagingSenderId: firebaseOptions.messagingSenderId,
        appId: firebaseOptions.appId,
        measurementId: firebaseOptions.measurementId,
    }
};

const firestoreInvoker = (options) => {
    const { firebaseEmulator, ...restProps } = options
    const app = initializeApp(firebaseConfig(restProps));
    const firestore = getFirestore(app);
    const auth = getAuth(app);
    if (firebaseEmulator === "true") {
        console.log("Firebase is running against emulator!")
        auth.useEmulator("http://localhost:9099")
        firestore.useEmulator("localhost", 9090)
    }

    return {
        firestore,
        auth
    }
}

export {
    firestoreInvoker,
    query,
    where,
    doc,
    onSnapshot,
    collection,
    signInWithCustomToken,
    getDocs,
    limit
}
