import { checkForNewAppRelease, reloadSite } from ".";

export const serviceWorkerMixin = {
  created() {
    window.addEventListener("sw-version-change", this.updateEventHandler);
  },
  mounted() {
    checkForNewAppRelease();
  },
  beforeDestroy() {
    window.removeEventListener("sw-version-change", this.updateEventHandler);
  },
  data() {
    return {
      shouldUpdate: false,
    }
  },
  computed: {
    currentRoute() {
      return this.$route;
    }
  },
  watch: {
    currentRoute() {
      if (!this.shouldUpdate) return;
      this.checkIfSiteShouldUpdate();
    }
  },
  methods: {
    updateEventHandler() {
      this.shouldUpdate = true;
      this.checkIfSiteShouldUpdate();
    },
    checkIfSiteShouldUpdate() {
      const inFlow = this.currentRoute.matched.some(r => {
        return r.meta.inFlow === true;
      });
      if (!inFlow) {
        reloadSite();
      }
    },
  }
}
