import { DigitalTwins, MassBalance, Material, Payment, SelectedMaterialAttribute } from "../materials/Material";
import { COUNT, Magnitude, MaterialAmount } from "../materials/MaterialAmount";
/**
 * It stands for one of two sides
 * that may be taken by a user/facility in a transaction:
 * Receiving or Delivering
 */
export class Manifest {
    /**
     * @param {Object} standardData
     * @returns {Manifest}
     */
    constructor(standardData) {
        /** @type {string} **/
        this.facilityId = standardData.facilityId;
        /** @type {string} **/
        this.facilityName = standardData.facilityName;
        /** @type {string} **/
        this.orgName = standardData.orgName;
        /** @type {string} **/
        this.registeredBy = standardData.registeredBy;
        /**
         * @type {InventoryItem[]}
         */
        this.inventoryItems = standardData.inventoryItems;
        if (standardData.metaData) {
            /** @type {MetaData} */
            this.metaData = new MetaData(standardData.metaData);
        }
    }
    /**
     * @param {Object} inventoryItemsObj
     * @param {String} facilityId
     * @param {String} facilityName
     * @param {String} orgName
     * @param {String} registeredBy
     * @param {Object | null} metaDataObj
     * @param {PreferredUnits} preferredUnits
     **/
    static fromApiResponse(inventoryItemsObj, facilityId, facilityName, orgName, registeredBy, metaDataObj, preferredUnits) {
        const validatedManifestData = {
            facilityId: facilityId,
            facilityName: facilityName,
            orgName: orgName,
            registeredBy: registeredBy,
            inventoryItems: this.validateInventoryItems(inventoryItemsObj, preferredUnits)
        };
        if (metaDataObj !== null) {
            validatedManifestData.metaData = MetaData.fromApiResponse(metaDataObj);
        }
        return new Manifest(validatedManifestData);
    }
    static empty(facilityId) {
        return new Manifest({
            facilityId: facilityId,
            inventoryItems: [],
            metaData: new MetaData({
                files: [],
            })
        });
    }
    /**
     * @param inventoryItemsObj
     * @param {PreferredUnits} preferredUnits
     * @returns {Array<InventoryItem>}
     */
    static validateInventoryItems(inventoryItemsObj, preferredUnits) {
        return inventoryItemsObj.map(item => {
            return InventoryItem.fromApiResponse(item, preferredUnits);
        });
    }
    containsMassBalance() {
        if (!this.inventoryItems.length) {
            return false;
        }
        return MASS_BALANCE.equals(this.inventoryItems[0].type);
    }
    containsDigitalTwin() {
        if (!this.inventoryItems.length) {
            return false;
        }
        return DIGITAL_TWIN.equals(this.inventoryItems[0].type);
    }
    /** @returns {Array<Magnitude>} **/
    getMagnitudes() {
        const magnitudes = [];
        this.inventoryItems.forEach(item => {
            magnitudes.push(item.magnitude);
        });
        return magnitudes;
    }
    areMagnitudesTheSame() {
        const magnitudes = this.getMagnitudes();
        return magnitudes.every(mag => magnitudes[0].equals(mag));
    }
    /** @return{EmpowerLocation} **/
    get location() {
        var _a;
        return (_a = this.metaData) === null || _a === void 0 ? void 0 : _a.location;
    }
    /** Set location
     * @param {EmpowerLocation} location
     */
    set location(location) {
        /**@type {EmpowerLocation} **/
        this.metaData.location = location;
    }
    /**
     * @returns {string}
     */
    get message() {
        var _a;
        return (_a = this.metaData) === null || _a === void 0 ? void 0 : _a.message;
    }
    set message(message) {
        this.metaData.message = message;
    }
    /**
     * @returns {EmpowerFile[]}
     */
    get files() {
        var _a;
        return ((_a = this.metaData) === null || _a === void 0 ? void 0 : _a.files) || [];
    }
    /**
     * Get user specified time
     * @returns {string}
     */
    get userSpecifiedTime() {
        var _a;
        return (_a = this.metaData) === null || _a === void 0 ? void 0 : _a.userSpecifiedTime;
    }
    /** Set user specified time
     * @param {string} time in ISO 8601 format
     */
    set userSpecifiedTime(time) {
        this.metaData.userSpecifiedTime = time;
    }
    /**
     * @param {Object[]} files
     */
    addFiles(files) {
        files.forEach(file => {
            var _a;
            if (!file.type) {
                file.type = OTHER_FILES;
            }
            const validType = FILE_TYPES.get(file.type);
            const constructorData = {
                type: validType,
                url: file.url,
                title: file.title,
                timeStampedTitle: (_a = file.timeStampedTitle) !== null && _a !== void 0 ? _a : undefined
            };
            const empFile = new EmpowerFile(constructorData);
            this.metaData.files.push(empFile);
        });
    }
    /**
     * @param {EmpowerFile} fileToRemove
     */
    removeFile(fileToRemove) {
        this.metaData.files = this.metaData.files.filter(file => {
            return fileToRemove.title !== file.title;
        });
    }
    /**
     * Return total payment for all inventory items
     * @returns {Payment}
     */
    get totalPayment() {
        return this.inventoryItems
            .map(item => item.isMassBalance ? item.massBalance.payment : item.digitalTwin.payment)
            .filter(payment => !!payment)
            .reduce((acc, curr) => curr.plus(acc), new Payment(0, 0));
    }
    /**
     * @returns {InventoryItem[]}
     */
    cloneInventoryItems() {
        return this.inventoryItems.map(item => item.clone());
    }
}
export class MetaData {
    /**
     * @param {Object} standardData
     * @returns {MetaData}
     **/
    constructor(standardData) {
        /** @type {string} **/
        this.userSpecifiedTime = standardData.userSpecifiedTime;
        /** @type {EmpowerFile[]} **/
        this.files = standardData.files.map(file => {
            return new EmpowerFile(file);
        });
        /** @type{EmpowerLocation} **/
        this.location = standardData.location ? new EmpowerLocation(standardData.location) : undefined;
        /** @type {string} **/
        this.message = standardData.message ? standardData.message : undefined;
    }
    static fromApiResponse(responseObj) {
        const validatedMetaData = {
            userSpecifiedTime: responseObj.manualDateTime,
            files: this.validateFiles(responseObj.files)
        };
        if (this.hasLocation(responseObj)) {
            validatedMetaData.location = this.validateLocation(responseObj.location);
        }
        if (this.hasMessage(responseObj)) {
            validatedMetaData.message = responseObj.message;
        }
        return new MetaData(validatedMetaData);
    }
    static validateLocation(locationObj) {
        return EmpowerLocation.fromApiResponse(locationObj);
    }
    static validateFiles(filesArray) {
        return filesArray.map(file => {
            return EmpowerFile.fromApiResponse(file.url, file.fileType, file.title ? file.title : null, file.timeStampedTitle ? file.timeStampedTitle : null);
        });
    }
    /**
     * @param {Object} metaData
     * @returns {Boolean}
     **/
    static hasMessage(metaData) {
        return !!(metaData.message);
    }
    /**
     * @param {Object} metaData
     * @returns {Boolean}
     **/
    static hasLocation(metaData) {
        return !!(metaData.location);
    }
}
export class InventoryItem {
    constructor(standardData) {
        this.type = standardData.type;
        if (standardData.item instanceof MassBalance) {
            /** @type {MassBalance} **/
            this.massBalance = standardData.item;
        }
        if (standardData.item instanceof DigitalTwins) {
            /** @type {DigitalTwins} **/
            this.digitalTwin = standardData.item;
        }
    }
    get isMassBalance() {
        return MASS_BALANCE.equals(this.type);
    }
    get isDigitalTwin() {
        return DIGITAL_TWIN.equals(this.type);
    }
    /** @returns {Magnitude} **/
    get magnitude() {
        return this.isMassBalance ? this.massBalance.amount.magnitude : COUNT;
    }
    /** @returns {MaterialAmount} **/
    get amount() {
        return this.isMassBalance ?
            this.massBalance.amount :
            MaterialAmount.fromCount(this.digitalTwin.amount);
    }
    /** @returns {Payment} **/
    get payment() {
        return this.isMassBalance ?
            this.massBalance.payment :
            this.digitalTwin.payment;
    }
    /**
     * @param {Payment} payment
     */
    set payment(payment) {
        if (this.isMassBalance) {
            this.massBalance.payment = payment;
        }
        else {
            this.digitalTwin.payment = payment;
        }
    }
    get name() {
        return this.isMassBalance ? this.massBalance.material.name : this.digitalTwin.name;
    }
    /**
     *
     * @param {boolean} digitalTwinAsBalance whether to encode optional digital twin fields as balance objects
     * @return {object} generic object json representation of the InventoryItem
     */
    toJson(digitalTwinAsBalance = true) {
        return this.isMassBalance ? this.massBalance.toJson() :
            (digitalTwinAsBalance ? this.digitalTwin.toBalanceJson() : this.digitalTwin.toJson());
    }
    static fromApiResponse(responseObj, preferredUnits) {
        const validType = INVENTORY_ITEM_TYPES.get(responseObj.type);
        if (!validType) {
            throw Error(`Inventory Item type ${responseObj.type} not found`);
        }
        const payment = responseObj.payment ? new Payment(responseObj.payment.amount, responseObj.payment.pricePerUnit) : undefined;
        if (validType === MASS_BALANCE) {
            const selectedMaterialAttributes = Object.entries(responseObj.massBalanceDefinition)
                .filter(([key]) => key !== 'plasticType')
                .map(([key, value]) => new SelectedMaterialAttribute(key, value));
            const massBalance = new MassBalance(new Material(responseObj.massBalanceDefinition.plasticType, new Magnitude(responseObj.magnitude), selectedMaterialAttributes), new MaterialAmount(responseObj.amount, new Magnitude(responseObj.magnitude), preferredUnits), payment);
            return InventoryItem.fromMassBalance(massBalance);
        }
        else if (validType === DIGITAL_TWIN) {
            /** @type {MassBalance[]} **/
            const dtInventory = responseObj.digitalTwinsInventory.map(responseObj => {
                const selectedMaterialAttributes = Object.entries(responseObj.massBalanceDefinition)
                    .filter(([key]) => key !== 'plasticType')
                    .map(([key, value]) => new SelectedMaterialAttribute(key, value));
                return new MassBalance(new Material(responseObj.massBalanceDefinition.plasticType, new Magnitude(responseObj.magnitude), selectedMaterialAttributes), new MaterialAmount(responseObj.amount, new Magnitude(responseObj.magnitude), preferredUnits));
            });
            const digitalTwin = new DigitalTwins(responseObj.digitalTwinsName, responseObj.numberOfDigitalTwins, responseObj.digitalTwinsCreatedTime, dtInventory, responseObj.digitalTwinsBalanceId, undefined, undefined, payment);
            return InventoryItem.fromDigitalTwins(digitalTwin);
        }
        else {
            throw Error(`Unknown item found for type ${validType}`); // should never happen
        }
    }
    /**
     * Clones an InventoryItem.
     * Current use case: Generating a Reception Manifest with
     * cloned Delivery Manifest Inventory Items
     * @returns {InventoryItem}
     */
    clone() {
        const clonedType = INVENTORY_ITEM_TYPES.get(this.type);
        if (!clonedType) {
            throw Error(`Inventory Item type ${this.type} not found`);
        }
        if (clonedType === MASS_BALANCE) {
            const clonedMassBalance = this.massBalance.clone();
            return InventoryItem.fromMassBalance(clonedMassBalance);
        }
        else if (clonedType === DIGITAL_TWIN) {
            const clonedDigitalTwin = this.digitalTwin.clone();
            return InventoryItem.fromDigitalTwins(clonedDigitalTwin);
        }
        else {
            throw Error(`Unknown item found for type ${clonedType}`); // should never happen
        }
    }
    /** Creates an InventoryItem from a MassBalance
     * @param {MassBalance} massBalance
     * @returns {InventoryItem}
     */
    static fromMassBalance(massBalance) {
        return new InventoryItem({
            type: MASS_BALANCE,
            item: massBalance
        });
    }
    /** Creates an InventoryItem from a DigitalTwins
     * @param {DigitalTwins} digitalTwins
     * @returns {InventoryItem}
     */
    static fromDigitalTwins(digitalTwins) {
        return new InventoryItem({
            type: DIGITAL_TWIN,
            item: digitalTwins
        });
    }
}
export class InventoryItemType extends String {
    constructor() {
        super(...arguments);
        this.equals = (other) => other.toString() === this.toString();
    }
}
export const MASS_BALANCE = new InventoryItemType('MASS_BALANCE');
export const DIGITAL_TWIN = new InventoryItemType('DIGITAL_TWIN');
class InventoryItemTypes extends Array {
    constructor() {
        super(...arguments);
        this.isValid = (elem) => {
            return this.some(el => el.equals(elem));
        };
        this.get = (elem) => {
            return this.find(el => el.equals(elem));
        };
    }
}
export const INVENTORY_ITEM_TYPES = new InventoryItemTypes(MASS_BALANCE, DIGITAL_TWIN);
export class EmpowerLocation {
    /**
     * @returns {EmpowerLocation}
     */
    constructor(standardData) {
        this.type = standardData.type;
        this.coordinates = standardData.coordinates;
    }
    /**
     * Represents a point object.
     * @typedef {Object} JsonLocation
     * @property {Array<number, number>} coordinates
     * @property {string} type
     */
    /**
     *
     * @param {JsonLocation} jsonLocation
     * @returns {EmpowerLocation}
     */
    static fromApiResponse(jsonLocation) {
        const validType = EMPOWER_LOCATION_TYPES.get(jsonLocation.type);
        if (!validType) {
            throw Error(`Location type: ${jsonLocation.type} not found`);
        }
        const validatedEmpLocation = {
            type: validType,
            coordinates: {
                lat: jsonLocation.coordinates[1],
                lng: jsonLocation.coordinates[0]
            }
        };
        return new EmpowerLocation(validatedEmpLocation);
    }
    /**
     * For use when a user begins creating a new Manifest
     * @param {JsonLocation|undefined} jsonLocation
     * @returns {EmpowerLocation}
     */
    static generateStarterLocation(jsonLocation) {
        /** 66 facilities in Prod without a location **/
        if (jsonLocation === undefined) {
            const defaultEmpowerLocation = {
                type: POINT,
                coordinates: {
                    lat: 59.909760,
                    lng: 10.765750
                }
            };
            return new EmpowerLocation(defaultEmpowerLocation);
        }
        const validType = EMPOWER_LOCATION_TYPES.get(jsonLocation.type);
        if (!validType) {
            throw Error(`Location type: ${jsonLocation.type} not found`);
        }
        const validatedEmpLocation = {
            type: validType,
            coordinates: {
                lat: jsonLocation.coordinates[1],
                lng: jsonLocation.coordinates[0]
            }
        };
        return new EmpowerLocation(validatedEmpLocation);
    }
    static point(lat, lng) {
        return new EmpowerLocation({
            coordinates: { lat: lat, lng: lng },
            type: POINT
        });
    }
    /** @returns {Number} **/
    get lat() {
        return this.coordinates.lat;
    }
    /** @returns {Number} **/
    get lng() {
        return this.coordinates.lng;
    }
}
export class EmpowerLocationType extends String {
    constructor() {
        super(...arguments);
        this.equals = (other) => other.toString() === this.toString();
    }
}
export const POINT = new EmpowerLocationType('POINT');
class EmpowerLocationTypes extends Array {
    constructor() {
        super(...arguments);
        this.isValid = (elem) => {
            return this.some(el => el.equals(elem));
        };
        this.get = (elem) => {
            return this.find(el => el.equals(elem));
        };
    }
}
export const EMPOWER_LOCATION_TYPES = new EmpowerLocationTypes(POINT);
export class EmpowerFile {
    constructor(standardData) {
        this.url = standardData.url;
        this.fileType = standardData.type;
        this.title = standardData.title;
        this.timeStampedTitle = standardData.timeStampedTitle;
    }
    /**
     * @param {String} url
     * @param {FileType} type
     * @param {String | null} title
     * @param {String | null} timeStampedTitle
     */
    static fromApiResponse(url, type, title, timeStampedTitle) {
        const validType = FILE_TYPES.get(type);
        if (!validType) {
            throw Error(`File type: ${type} not found`);
        }
        const validatedFileData = {
            url: url,
            fileType: validType,
            title: title,
            timeStampedTitle: timeStampedTitle
        };
        return new EmpowerFile(validatedFileData);
    }
}
export class FileType extends String {
    constructor() {
        super(...arguments);
        this.equals = (other) => other.toString() === this.toString();
    }
}
export const REGISTRATION_DOCUMENT = new FileType('REGISTRATION_DOCUMENT');
export const EXPORT_LICENSE_DOCUMENT = new FileType('EXPORT_LICENSE_DOCUMENT');
export const CLEAN_UP_IMAGE = new FileType('CLEAN_UP_IMAGE');
export const OTHER_FILES = new FileType('OTHER_FILES');
class FileTypes extends Array {
    constructor() {
        super(...arguments);
        this.isValid = (elem) => {
            return this.some(el => el.equals(elem));
        };
        this.get = (elem) => {
            return this.find(el => el.equals(elem));
        };
    }
}
export const FILE_TYPES = new FileTypes(REGISTRATION_DOCUMENT, EXPORT_LICENSE_DOCUMENT, CLEAN_UP_IMAGE, OTHER_FILES);
