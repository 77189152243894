import { Decimal } from 'decimal.js';
/**
 * MaterialAmount represents an amount of a Magnitude of a Material,
 * internally encoded in our Base Unit of that Magnitude. And allows
 * to easily represent the amount in the preferred Unit of the User.
 *
 * Examples:
 *     * A weight of 320 kilograms. Which the user might want to see
 *       expressed as 0.32 metric tons
 *     * A volume of 1 cubic meter. Which the user might want to see
 *       expressed as 1000 litres
 *
 * The actual material itself is not relevant for this class.
 *
 * Usage:
 *
 * The method toString() should produce a human-readable representation of
 * the amount, expressed in the User's preferred units. use this amount to
 * display to the users.
 *
 * The method toNumber() should produce a machine-readable representation
 * of the amount, expressed in our Base Units. Use this amount to send
 * to the backend
 *
 * When you have amounts expressed in Base Units (like the amounts
 * that the backend provides), use the constructor of this class to
 * initialize an instance. Example:
 *
 *      const tenKilos = 10;
 *      const preferredUnits = PreferredUnits(METRIC_TONS, LITRES)
 *      const a = MaterialAmount(tenKilos, WEIGHT, preferredUnits)
 *      a.toString()  // "0.01 t"
 *      a.toNumber()  // 10
 *
 * When you have amounts expressed in Used-Preferred units (like user
 * input), use the method MaterialAmount.fromPreferredUnit to initialize
 * an instance of this class. Example:
 *
 *      const tenTons = 10;
 *      const preferredUnits = PreferredUnits(METRIC_TONS, LITRES)
 *      const a = MaterialAmount.fromPreferredUnit(tenTons, WEIGHT, preferredUnits)
 *      a.toString()  // "10 t"
 *      a.toNumber()  // 10000
 */
export class MaterialAmount extends Decimal {
    /**
     * Initializes a MaterialAmount from an amount EXPRESSED IN BASE
     * UNITS of its magnitude.
     *
     * @param {number | string | Decimal} amountBaseUnit the amount
     * expressed in the magnitude's base unit.
     * @param {Magnitude | string} magnitude the magnitude of the amount
     * @param {PreferredUnits} preferredUnits the User's preferred units
     */
    constructor(amountBaseUnit, magnitude, preferredUnits) {
        if (!MAGNITUDES.isValid(magnitude)) {
            throw new InvalidMagnitude(magnitude);
        }
        if (!PreferredUnits.isValid(preferredUnits)) {
            throw new InvalidPreferredUnit("Invalid preferred units: " + preferredUnits);
        }
        super(amountBaseUnit);
        /**
         * Used to return only the preferred unit
         * as a standalone string
         * @returns {string}
         */
        this.prefUnitToString = () => {
            return `${this.prefUnit}`;
        };
        /**
         * Used to return only the amount (number)
         * as a standalone string
         * @returns {string}
         */
        this.amountNumberToString = () => {
            const decimalPlaces = this.prefUnit.decimalPlaces;
            const rounded = this.inPrefUnits.toDecimalPlaces(decimalPlaces);
            /**
             * Hack Alert! Decimal itself doesn't have a toLocaleString method built-in.
             * So we're transforming to Number, which does have a localization
             * method.
             *
             * Decimal.js does have a sidecar package that handles formatting, but
             * it's not been udpated in 5 years, and I don't think it's worth
             * introducing it for our limited formatting needs
             */
            const localized = rounded.toNumber().toLocaleString(
            // undefined forces to use the default locale
            undefined, {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
                // this prevents the usage of thousands-separator.
                useGrouping: false,
            });
            return `${localized}`;
        };
        /**
         * Used to return only the amount (number)
         * in the USER'S PREFERRED UNITS.
         * Not to be confused with .toNumber(), which
         * will return the amount in BASE UNITS.
         * @returns {number}
         */
        this.amountNumber = () => {
            const decimalPlaces = this.prefUnit.decimalPlaces;
            const rounded = this.inPrefUnits.toDecimalPlaces(decimalPlaces);
            return rounded.toNumber();
        };
        /**
         * A human-readable representation of the amount, expressed in the
         * preferred unit, rounded to the desired number of decimal places,
         * and formatted to the user's locale
         * Examples:
         *     * "32.7 kg"
         *     * "14.02 m³"
         *     * "44.1 units"
         *
         * @param {number} decimalPlaces
         *
         * @returns {string}
         */
        this.toString = () => {
            const decimalPlaces = this.prefUnit.decimalPlaces;
            const rounded = this.inPrefUnits.toDecimalPlaces(decimalPlaces);
            /**
             * Hack Alert! Decimal itself doesn't have a toLocaleString method built-in.
             * So we're transforming to Number, which does have a localization
             * method.
             *
             * Decimal.js does have a sidecar package that handles formatting, but
             * it's not been udpated in 5 years, and I don't think it's worth
             * introducing it for our limited formatting needs
             */
            const localized = rounded.toNumber().toLocaleString(
            // undefined forces to use the default locale
            undefined, {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
                // this prevents the usage of thousands-separator.
                useGrouping: false,
            });
            return `${localized} ${this.prefUnit}`;
        };
        this.plus = (other) => {
            if (other.magnitude && !this.magnitude.equals(other.magnitude)) {
                throw new InvalidMagnitude(`Cannot sum ${this.magnitude} and ${other.magnitude}`);
            }
            const decimalResult = super.plus(other);
            return new MaterialAmount(decimalResult, this.magnitude, this.preferredUnits);
        };
        this.minus = (other) => {
            if (other.magnitude && !this.magnitude.equals(other.magnitude)) {
                throw new InvalidMagnitude(`Cannot sum ${this.magnitude} and ${other.magnitude}`);
            }
            const decimalResult = super.minus(other);
            return new MaterialAmount(decimalResult, this.magnitude, this.preferredUnits);
        };
        this.times = (other) => {
            if (other.magnitude && !this.magnitude.equals(other.magnitude)) {
                throw new InvalidMagnitude(`Cannot sum ${this.magnitude} and ${other.magnitude}`);
            }
            const decimalResult = super.times(other);
            return new MaterialAmount(decimalResult, this.magnitude, this.preferredUnits);
        };
        this.dividedBy = (other) => {
            if (other.magnitude && !this.magnitude.equals(other.magnitude)) {
                throw new InvalidMagnitude(`Cannot sum ${this.magnitude} and ${other.magnitude}`);
            }
            const decimalResult = super.dividedBy(other);
            return new MaterialAmount(decimalResult, this.magnitude, this.preferredUnits);
        };
        this.abs = () => {
            const val = super.abs();
            return new MaterialAmount(val, this.magnitude, this.preferredUnits);
        };
        this.equals = (other) => {
            return other instanceof MaterialAmount
                && super.equals(other)
                && other.magnitude.equals(this.magnitude)
                && other.preferredUnits.equals(this.preferredUnits);
        };
        /**
         * TODO: maybe we can refactor Magnitude's constructor
         * to deal with this
         */
        if (magnitude instanceof Magnitude) {
            /** @type {Magnitude} **/
            this.magnitude = magnitude;
        }
        else {
            /** @type {Magnitude} **/
            this.magnitude = new Magnitude(magnitude);
        }
        /** @type {PreferredUnits} **/
        this.preferredUnits = preferredUnits;
    }
    /**
     * prefUnit: The preferred unit to represent this magnitude.
     * @returns {Unit}
     */
    get prefUnit() {
        return this.preferredUnits.getForMagnitude(this.magnitude);
    }
    /**
     * inPrefUnits: The amount expressed in the preferred unit for
     * its magnitude
     * @returns {Decimal}
     */
    get inPrefUnits() {
        const prefUnit = this.prefUnit;
        return prefUnit.fromBaseUnit(this);
    }
    /**
     * Clones a MaterialAmount
     * @returns {MaterialAmount}
     */
    clone() {
        return new MaterialAmount(this.toNumber(), new Magnitude(this.magnitude), this.preferredUnits);
    }
    /**
     * Initializes a MaterialAmount from an amount EXPRESSED IN THE USER'S
     * PREFERRED UNITS. The amount will be converted to Base Units of its
     * magnitude, before initializing the MaterialAmount instance.
     *
     * @param {number | string | Decimal} amountPrefUnit the amount
     * expressed in the User's preferred unit
     * @param {Magnitude} magnitude the magnitude of the amount
     * @param {PreferredUnits} preferredUnits the User's preferred units
     *
     * @returns {MaterialAmount}
     */
    static fromPreferredUnit(amountPrefUnit, magnitude, preferredUnits) {
        const prefUnit = preferredUnits.getForMagnitude(magnitude);
        const amount = Decimal(amountPrefUnit)
            .toDecimalPlaces(prefUnit.decimalPlaces, Decimal.ROUND_DOWN);
        const amountBaseUnit = prefUnit.toBaseUnit(amount);
        return new MaterialAmount(amountBaseUnit, magnitude, preferredUnits);
    }
    static fromCount(amountNumber, preferredUnits = DEFAULT_PREFERRED_UNITS) {
        return new MaterialAmount(amountNumber, COUNT, preferredUnits);
    }
}
/**
 * PreferredUnits holds the user-configured preferred units
 * for each magnitude. If not explicitly selected, it defaults
 * to base units of the Internation System of Units.
 * https://en.wikipedia.org/wiki/International_System_of_Units
 */
export class PreferredUnits {
    constructor(weight = KILOGRAM, volume = CUBIC_METER) {
        this.count = COUNT_UNIT;
        this.weight = null;
        this.volume = null;
        /**
         * Fetches the preferred unit for a particular magnitude.
         * @param {Magnitude} magnitude
         * @returns
         */
        this.getForMagnitude = (magnitude) => {
            if (WEIGHT.equals(magnitude))
                return this.weight;
            if (VOLUME.equals(magnitude))
                return this.volume;
            if (COUNT.equals(magnitude))
                return this.count;
            throw new InvalidMagnitude(magnitude);
        };
        /**
         * TODO: eventually move this logic to dedicated schemas that
         * deal with serialization/deserialization. We're not set up
         * for that at the moment, so I'm leaving this in place.
         */
        this.toJson = () => ({ weight: this.weight.id, volume: this.volume.id });
        this.equals = (other) => {
            return other instanceof PreferredUnits
                && other.weight.equals(this.weight)
                && other.volume.equals(this.volume);
        };
        if (!WEIGHT_UNITS.isValid(weight)) {
            throw new InvalidUnit(`invalid weight unit: ${weight}`);
        }
        this.weight = weight;
        if (!VOLUME_UNITS.isValid(volume)) {
            throw new InvalidUnit(`invalid volume unit: ${volume}`);
        }
        this.volume = volume;
    }
}
PreferredUnits.isValid = (prefUnits) => prefUnits instanceof PreferredUnits;
/**
 * Creates a PreferredUnits instance from the `preferredUnits`
 * JSON information provided by the Organization endpoint.
 *
 * TODO: eventually move this logic to dedicated schemas that
 * deal with serialization/deserialization. We're not set up
 * for that at the moment, so I'm leaving this in place.
 */
PreferredUnits.fromJson = ({ weight, volume }) => {
    const weightUnit = WEIGHT_UNITS.get(weight);
    const volUnit = VOLUME_UNITS.get(volume);
    if (!weightUnit || !volUnit) {
        /**
         * Ideally this would throw an error, because it
         * was not provided 2 correct units. But I'm
         * leaving this default for simplicity for now.
         */
        return DEFAULT_PREFERRED_UNITS;
    }
    return new PreferredUnits(weightUnit, volUnit);
};
export class Unit {
    constructor({ id, name, symbol, magnitude, isBase = true, factorToBase = Decimal('1'), shortName = null, decimalPlaces = 2 } = {}) {
        this.id = null;
        this.name = null;
        this.symbol = null;
        this.magnitude = null;
        this.isBase = null;
        this.factorToBase = null;
        this.shortName = null;
        /**
         * decimalPlaces allows specifying the number of decimal
         * places that makes sense accepting/displaying for the unit.
         *
         * For example, count units are conceptually integers. So they
         * only make sense with 0 decimal places.
         *
         * Most units might make sense with 2 decimal places, so that's
         * set as default. But large units (like tons) could make sense
         * with 3 decimal places.
         *
         * This allows setting/configuring that in a centrallized location.
         */
        this.decimalPlaces = null;
        this.toString = () => this.symbol;
        /**
         * Transforms from the base unit (for example cubir meter or kilogram)
         * to this unit.
         * @param {number | string | Decimal} amount the amount expressed in base unit
         * @returns {Decimal} the amount expressed in desired unit
         */
        this.fromBaseUnit = (amount) => Decimal(amount).div(this.factorToBase);
        /**
         * Transforms to the base unit (for example cubir meter or kilogram)
         * from this unit.
         * @param {number | string | Decimal} amount the amount expressed in this unit
         * @returns {Decimal} the amount expressed in base unit
         */
        this.toBaseUnit = (amount) => Decimal(amount).times(this.factorToBase);
        this.equals = (other) => {
            return this.id == other.id;
        };
        this.id = id;
        this.name = name;
        this.symbol = symbol;
        this.magnitude = magnitude;
        this.isBase = isBase;
        this.factorToBase = factorToBase;
        // If shortName is not provided, use name as shortName also.
        this.shortName = shortName || name;
        this.decimalPlaces = decimalPlaces;
    }
    /**
     * step calculates the value that can be used in <input step=x />
     * for elements that hold values for this unit.
     *
     * Examples:
     *   * For COUNT you'd want integer steps (1 bottle, 4 cans, etc),
     *     so this would return 1
     *
     *   * For most units you'd want to display 2 decimal places (30.02 kg,
     *     0.02 t, etc). So this would return 0.01
     * @returns {String}
     */
    get step() {
        const factor = Decimal(10).toPower(Decimal(this.decimalPlaces));
        return Decimal(1).dividedBy(factor).toString();
    }
}
export class Magnitude extends String {
    constructor() {
        super(...arguments);
        this.equals = (other) => other.toString() == this.toString();
    }
}
export const WEIGHT = new Magnitude('WEIGHT');
export const VOLUME = new Magnitude('VOLUME');
export const COUNT = new Magnitude('COUNT');
export class Magnitudes extends Array {
    constructor() {
        super(...arguments);
        this.isValid = (elem) => {
            return this.some(el => el.equals(elem));
        };
        this.get = (elem) => {
            return this.find(el => el.equals(elem));
        };
    }
}
export const MAGNITUDES = new Magnitudes(WEIGHT, VOLUME, COUNT);
export const KILOGRAM = new Unit({
    id: "KILOGRAMS",
    name: 'Kilogram',
    symbol: 'kg',
    magnitude: WEIGHT,
    shortName: 'Kilo'
});
export const METRIC_TON = new Unit({
    id: 'METRIC_TONS',
    name: 'Metric ton',
    symbol: 't',
    magnitude: WEIGHT,
    isBase: false,
    factorToBase: Decimal('1000'),
    shortName: 'Ton',
});
export const POUND = new Unit({
    id: 'POUNDS',
    name: 'Pound',
    symbol: 'lbs',
    magnitude: WEIGHT,
    isBase: false,
    factorToBase: Decimal('0.45359237')
});
export const CUBIC_METER = new Unit({
    id: 'CUBIC_METERS',
    name: 'Cubic meter',
    symbol: 'm³',
    magnitude: VOLUME,
});
export const LITRE = new Unit({
    id: 'LITRES',
    name: 'Litre',
    symbol: 'l',
    magnitude: VOLUME,
    isBase: false,
    factorToBase: Decimal('0.001')
});
// See this for context on different gallons https://en.wikipedia.org/wiki/Gallon
export const IMPERIAL_GALLON = new Unit({
    id: 'IMPERIAL_GALLONS',
    name: 'Imperial gallon',
    symbol: 'gal',
    magnitude: VOLUME,
    isBase: false,
    factorToBase: Decimal('0.00454609'),
    shortName: 'Gallon'
});
export const US_GALLON = new Unit({
    id: 'US_GALLONS',
    name: 'US gallon',
    symbol: 'gal',
    magnitude: VOLUME,
    isBase: false,
    factorToBase: Decimal('0.00378541'),
    shortName: 'Gallon'
});
export const COUNT_UNIT = new Unit({
    id: 'COUNT_UNITS',
    name: 'Count unit',
    symbol: 'units',
    magnitude: COUNT,
    shortName: 'unit',
    /**
     * Counts are conceptually an integer. So they only
     * make sense with zero decimal places
     */
    decimalPlaces: 0,
});
class UnitsArray extends Array {
    constructor() {
        super(...arguments);
        this.isValid = (other) => this.some(el => el.equals(other));
        this.get = (id) => this.find(el => el.id == id);
    }
}
export const WEIGHT_UNITS = new UnitsArray(KILOGRAM, METRIC_TON, POUND);
export const VOLUME_UNITS = new UnitsArray(CUBIC_METER, LITRE, IMPERIAL_GALLON, US_GALLON);
export const COUNT_UNITS = new UnitsArray(COUNT_UNIT);
export const UNITS = new UnitsArray(KILOGRAM, METRIC_TON, POUND, CUBIC_METER, LITRE, IMPERIAL_GALLON, US_GALLON, COUNT_UNIT);
export const DEFAULT_PREFERRED_UNITS = new PreferredUnits();
export class InvalidPreferredUnit extends Error {
}
export class InvalidUnit extends Error {
}
export class InvalidMagnitude extends Error {
}
