import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import {apiService, depositService, facilityService, userService, wasteService} from "@/main";
import {digitalTwinStore} from "./DigitalTwinStore";
import {areMassBalanceDefinitionsEqual} from "@empower-platform/tracking-frontend-shared-commons";
import {
    Magnitude,
    MaterialAmount,
    PreferredUnits
} from '@empower-platform/tracking-frontend-shared-types-js/build/materials/MaterialAmount';
import { Organization } from '@/types/Organization';
import { organizationService } from '@/main';
import {
    MassBalance,
    DigitalTwins,
    MaterialFamily
} from '@empower-platform/tracking-frontend-shared-types-js/build/materials/Material';
import cloneDeep from 'lodash.clonedeep'
import Decimal from 'decimal.js';
import { HistoryStore } from './HistoryStore';
import { messageStore } from "@/store/MessageStore";
import {
    DIGITAL_TWIN,
    mapTxsFromApi, MASS_BALANCE,
    Transaction
} from "@empower-platform/tracking-frontend-shared-types-js/build/transactions";


Vue.use(Vuex);

const initialStoreState = {
    organizationId: null,
    facilityId: null,
    /** @type {Array<MassBalance|DigitalTwins>} **/
    inventory: [],
    materialCategoryList: [],
    shouldUpdateInventory: false,
    organizations: [],
    organization: null,
    facilities: [],
    facility: null,
    /**@type {Transaction[]} **/
    transactionRequests: [],
    dataStorePopulated: false,
    isCollectionPoint: null,
    header: {
        title: '',
        titleSmall: '',
        titleLeft: '',
        titleRight: '',
        leftBtn: false,
        leftBtnFunction: null,
        rightBtn: false,
        rightBtnFunction: null,
        displayArrowForward: false,
        allowUserToGoForward: false
    },
    showBanner: false,
    banner: {
        title: '',
        content: '',
        extraContent: '',
        buttonText: '',
        buttonClick: () => {},
        radioClick: () => {},
        changeColor: false,
        changeFont: false,
        confirmation: false
    },
    showBottomNav: false,
    showHeader: true,
    changeBackgroundColor: false,
    paymentRegistrationEnabled: false,
    limitDeliveriesToQrCodes: null,
    isDelterraPilotMember: null,
    balanceToDeliverFrom: null,
};

const store = new Vuex.Store({
    modules: {
        digitalTwin: digitalTwinStore,
        history: HistoryStore,
        message: messageStore
    },
    state: {
        ...initialStoreState,
    },
    actions: {
        async getUser({commit}) {
            const user = await userService.getUserInfo()
            commit('setUser', user);
        },
        async getOrganizationId({commit}) {
            const organizationId = await userService.getOrganizationId();
            commit('setOrganizationId', organizationId);
        },
        async getOrganization({commit}) {
            if (!this.state.organizationId) {
                throw new Error("Organization ID not set");
            }
            const org = await organizationService.getOrganization(this.state.organizationId);
            commit('setOrganization', org)
            commit('setOrganizationId', org.organizationId)
        },
        async getInventory({commit}) {
            const inventory = await wasteService.getInventory();
            const balances = inventory.balances.filter(b => b.amount > 0);
            commit('setInventory', balances);
        },
        async getMaterialCategoryList({commit}) {
            // on changes to material types, ably should trigger this to run again
            const resp = await facilityService.getMaterialCategoryList();
            const categories = resp.types.map(el => MaterialFamily.fromJson({...el}))
            commit('setMaterialCategoryList', categories);
        },
        async getMaterialCategoryListDelterra({commit}) {
            const resp = await facilityService.getMaterialCategoryListDelterra();
            const categories = resp.types.map(el => MaterialFamily.fromJson({...el}))
            commit('setMaterialCategoryList', categories);
        },
        /**
         * Performs the necessary mapping of raw API data to instances of Transaction class
         */
        async getRequests({commit, getters}) {
            const apiResponse= await wasteService.getAllTransactionRequests()
            const opaqueTxs = apiResponse.requests;
            const transactions = mapTxsFromApi(opaqueTxs, getters.preferredUnits)
            commit('setTransactionRequests', transactions);
        },
        async getSelectedFacility({commit}, facilityId) {
            try {
                const facility = await facilityService.getFacilityInfo(facilityId);
                commit('setSelectedFacility', facility);
            } catch (e) {
                commit('setError', e.message);
            }
        },
        async setFacility({commit, dispatch}) {
            wasteService.setFacilityId();
            dispatch('resetAllDataStores');
            commit('setDataStorePopulated', false);
        },
        async setUserLocation({ commit }, $getLocation) {
            $getLocation({ enableHighAccuracy: true }).then(location => {
                commit('setUserLocation', location);
            }).catch(() => {
                return
            });
        },
        resetAllDataStores({commit}) {
            commit('resetCoreStore');
            commit('resetDigitalTwinStore');
            commit('resetHistoryStore');
        },
        async dataStorePopulated({commit}) {
            commit('setDataStorePopulated', true)
        },
        async checkIfCollectionPoint({commit}) {
            const response = await depositService.isCollectionPoint()
            commit('setIsCollectionPoint', response.isCollectionPoint);
        },
        async cancelDeliveryRequest({state, commit}, {requestCode, cancelReason}) {
            await wasteService.manualCancelTransactionRequest(requestCode, cancelReason);
        },
        async logout({dispatch}) {
            await userService.logout();
            apiService.setToken();
            dispatch('resetAllDataStores');
        },
        async checkIfBannerShouldShow({commit}, bannerInfo) {
            const shouldShow = await checkIfBannerShouldShow(bannerInfo.route);
            if (shouldShow) {
                await commit('setBanner', bannerInfo);
                commit('toggleBanner', true);
            }
        },

    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setUserLocation(state, location) {
            state.userLocation =  {
                type: "POINT",
                coordinates: [
                    location.lng,
                    location.lat
                ]
            }
        },
        setBanner(state, bannerInfo) {
            const banner = {...state.banner};
            banner.route = bannerInfo.route;
            banner.title = bannerInfo.title || '';
            banner.content = bannerInfo.content || '';
            banner.extraContent = bannerInfo.extraContent || '';
            banner.buttonText = bannerInfo.buttonText || '';
            banner.buttonClick = bannerInfo.buttonClick || banner.buttonClick;
            banner.radioClick = bannerInfo.radioClick || banner.radioClick;
            banner.changeColor = bannerInfo.changeColor || false;
            banner.changeFont = bannerInfo.changeFont || false;
            banner.confirmation = bannerInfo.confirmation || false;
            state.banner = banner;
        },
        toggleBanner(state, status) {
            state.showBanner = status;
        },
        setShowBottomNav(state, status) {
            state.showBottomNav = status;
        },
        setShowHeader(state, status) {
            state.showHeader = status;
        },
        setChangeBackgroundColor(state, status) {
            state.changeBackgroundColor = status;
        },
        setHeader(state, newHeader) {
            const header = {};
            header.title = newHeader.title || '';
            header.titleSmall = newHeader.titleSmall || '';
            header.titleLeft = newHeader.titleLeft || '';
            header.titleRight = newHeader.titleRight || '';
            header.leftBtn = newHeader.leftBtn || false;
            header.leftBtnFunction = newHeader.leftBtnFunction || null;
            header.rightBtn = newHeader.rightBtn || false;
            header.rightBtnFunction = newHeader.rightBtnFunction || null;
            header.makeTextBlue = newHeader.makeTextBlue || false;
            header.displayArrowForward = newHeader.displayArrowForward || false;
            header.allowUserToGoForward = newHeader.allowUserToGoForward || false;
            state.header = header;
        },
        setInventory(state, inventory) {
            state.inventory = inventory.map(
                el => MassBalance.fromJson(el, this.getters.preferredUnits)
            );
            state.shouldUpdateInventory = false;
        },
        setSelectedFacility(state, facility) {
            state.facility = facility;
            state.paymentRegistrationEnabled = facility.isPaymentEnabled;
            state.limitDeliveriesToQrCodes = facility.limitDeliveriesToQrCodes;
        },
        /**
         * @param {rootState} state
         * @param {MaterialFamily[]} materialTypes
         */
        setMaterialCategoryList(state, materialTypes) {
            state.materialCategoryList = [...materialTypes];
        },
        /**
         * @param state // vuex state
         * @param {Transaction[]} transactions
         */
        setTransactionRequests(state, transactions) {
            state.transactionRequests = transactions;
        },
        resetCoreStore(rootState) {
            Object.assign(rootState, cloneDeep(initialStoreState));
        },
        setDataStorePopulated(state, status) {
            state.dataStorePopulated = status;
        },
        setIsCollectionPoint(state, status) {
            state.isCollectionPoint = status;
        },
        setOrganizationId(state, orgId) {
            state.organizationId = orgId;
        },
        setOrganization(state, orgData) {
            const preferredUnits = PreferredUnits.fromJson(
                orgData.preferredUnits
            );
            state.isDelterraPilotMember = orgData.isDelterraPilot
            state.organization = {...orgData, preferredUnits};
        },
        setCurrentBalanceToDeliverFrom(state, balanceToDeliverFrom) {
            state.balanceToDeliverFrom = balanceToDeliverFrom;
        },
        resetCurrentBalanceToDeliverFrom(state) {
            state.balanceToDeliverFrom = null;
        },
        /**
         * Updates a transaction in transactionRequests with transaction found in Ably message
         * @param state // vuex state
         * @param {Transaction} transaction
         */
        updateTransactionRequest(state, transaction) {
            const existingTransaction = state.transactionRequests.findIndex(request => {
                // if/else for backwards compatibility between transaction: Transaction && request: Transaction || OpaqueTransactionObject
                if (request.requestCode) {
                    return request.requestCode === transaction.requestCode
                } else {
                    return request.requestData.requestCode === transaction.requestCode
                }
            });
            if (existingTransaction > -1) {
                state.transactionRequests.splice(existingTransaction, 1, transaction);
            } else {
                state.transactionRequests = [transaction, ...state.transactionRequests];
            }
        },
        /**
         * Increases Inventory from Ably message
         * @param state // vuex state
         * @param {InventoryItem[]} incomingInventoryItems
         */
        async increaseInventoryMassBalance(state, incomingInventoryItems) {
            for (const incomingItem of incomingInventoryItems) {
                const massBalanceIndex = state.inventory.findIndex(massBalance => {
                    if (incomingItem.type.equals(DIGITAL_TWIN)) {
                        return false // Used to happen 'silently'. Now more explicit.
                    }
                    return incomingItem.massBalance.material.equals(massBalance.material)
                });
                if (massBalanceIndex > -1) {
                    const massBalance = state.inventory[massBalanceIndex]
                    if (massBalance instanceof DigitalTwins) {
                        throw Error('Cannot update inventory of type Digital Twins')
                    }

                    massBalance.amount = massBalance.amount.plus(new Decimal(incomingItem.massBalance.amount));
                    state.inventory.splice(massBalanceIndex,1, massBalance);
                } else {
                    state.inventory.unshift(incomingItem.massBalance.clone())
                }
            }
        },
        /**
         * Decreases Inventory from Ably message
         * @param state // vuex state
         * @param {InventoryItem[]} outgoingInventoryItems
         */
        decreaseInventoryMassBalance(state, outgoingInventoryItems) {
            outgoingInventoryItems.forEach(outgoingItem => {
                const massBalanceIndex = state.inventory.findIndex(inventoryItem => {
                    if (outgoingItem.type.equals(DIGITAL_TWIN)) {
                        return false // Used to happen 'silently'. Now more explicit.
                    }
                    return outgoingItem.massBalance.material.equals(inventoryItem.material)
                });
                if (massBalanceIndex > -1) {
                    const massBalance = state.inventory[massBalanceIndex]
                    if (massBalance instanceof DigitalTwins) {
                        throw Error('Cannot update inventory of type Digital Twins')
                    }
                    massBalance.amount = massBalance.amount.minus(new Decimal(outgoingItem.massBalance.amount));
                    // If massbalance becomes 0 remove it from the inventory
                    if (massBalance.amount.toNumber() > 0) {
                        state.inventory.splice(massBalanceIndex, 1, massBalance);
                    } else {
                        state.inventory.splice(massBalanceIndex, 1);
                    }
                }
            })
        },
    },
    getters: {
        getInventoryGreaterThanZero: (state) => {
            return state.inventory.filter(item => {
                return item.amount.amountNumber() > 0;
            })
        },
        /**
         * Takes a currentMaterial (Material),
         * and array of previously selected Materials,
         * and compares them with the inventory state.
         * Returns the inventory amount that the user
         * still has left to deliver from
         * when making a batch delivery.
         */
        getMaxAmountForBatchDelivery: (state) => (currentMaterial, previouslySelectedMassBalances) => {
            let inventory = state.inventory;
            // Scenarios:
            /**
             * First material. Just return that mass balance amount as the max.
             */
            if (!previouslySelectedMassBalances.length) {
                let massBalance = inventory.find(i => i.massBalanceKey === currentMaterial.massBalanceKey);
                return massBalance.amount
            }
            /**
             * Material being added matches one already added from the inventory.
             * Subtract that amount from the mass balance amount for the max.
             */
            let previouslySelectedMassBalance = previouslySelectedMassBalances.find(i => i.material.massBalanceKey === currentMaterial.massBalanceKey);
            if (previouslySelectedMassBalance) {
                let massBalance = inventory.find(i => i.massBalanceKey === previouslySelectedMassBalance.material.massBalanceKey);
                return massBalance.amount.minus(previouslySelectedMassBalance.amount)
            }
            /**
             * Material being added does not match any previously added mass balance.
             * Just return that mass balance amount as the max.
             */
            let massBalance = inventory.find(i => i.massBalanceKey === currentMaterial.massBalanceKey);
            return massBalance.amount
        },
        newDeliveryRequests: state => {
            return state.transactionRequests.filter(req => {
                return req.status === 'INITIATED'
            });
        },
        getInventoryItemFromSerialNumber: state => serialNumber => {
            return state.inventory.find(item => {
                return item.material != null &&
                    item.material.attributes != null &&
                    item.material.attributes.find(prop => {
                        return prop.name === "serial-number" && prop.value === serialNumber;
                    });
            });
        },

        incomingTransactionBySerialNumber: state => serialNumber => {
            return state.transactionRequests
                .filter(transaction => {
                    return transaction.isInitiated() &&
                      transaction.isIncomingRemoteDelivery() &&
                      transaction.delivery.containsMassBalance()
                })
                .find(transaction => {
                    return transaction.delivery.inventoryItems.find( inventoryItem => {
                        return inventoryItem.isMassBalance &&
                          inventoryItem.massBalance.getAttribute('serial-number')?.value === serialNumber;
                    })
                });
        },

        preferredUnits: state => {
            return state.organization.preferredUnits;
        },
        /**
         * Returns a list of all the known materials of the Facility.
         * Known Materials are the combination of:
         *   1. Materials configured on the Facily's Material Settings
         *   2. Materials that the Facility has a balance of
         * @returns  {Array<MaterialFamily>}
         */
        knownMaterials: state => {
            const fromMassBalance = state.inventory.map(el => {
                return MaterialFamily.fromMassBalance(el)
            })
            const fromMaterialSettings = state.materialCategoryList
            return MaterialFamily.mergeFamilies(
                fromMassBalance,
                fromMaterialSettings,
            )
        },
        defaultDecimalPlaceholder: state => {
            /**
             * defaultDecimalPlaceholder produces a '0.0' or '0,0' string
             * based on the language configuration of the user.
             *
             * We want to use this string as placeholder for the numeric inputs
             * of the platform.
             *
             * The reason for matching the user's language configuration is to
             * avoid causing the confusion described in the following issue:
             * https://github.com/EmpowerPlastic/empower-platform/issues/1914
             *
             * The reason for not storing this in a constant, is because we
             * don't want it generated at compile-time. We want it generated at
             * run-time, on the user's browser.
             */
            return (0.0).toLocaleString(
                // undefined means "use the browser's language/locale configuration"
                undefined,
                {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                    // this prevents the usage of thousands-separator.
                    useGrouping: false,
                },
            )
        },
        readableUserName: state => {
            if (!state.user) {
                throw Error('User not found')
            }
            return state.user.firstName + ' ' + state.user.lastName
        },
        /**
         * Returns either the facility location
         * or user location to be used as the
         * default location for a transaction.
         * Preference given to facility location.
         */
        defaultTxLocation: state => {
            const facilityLocation = state.facility.location
            const userLocation = state.userLocation
            return facilityLocation ? facilityLocation : userLocation
        }
    },
});
window.store = store;
export default store;

const checkIfBannerShouldShow = () => {
    let banners = localStorage.getItem('banners');
    let currentRoute = router.history.current.path;
    let show = true;
    if (banners) {
        banners = JSON.parse(banners);
        if (banners[currentRoute]) {
            show = banners[currentRoute].showBanner ? true : false;
        }
    }
    return show;
}

/**
 * Should be removed and use Transaction class from shared code.
 */
export function hydrateTxAmounts(tx, prefUnits) {
    const delivered = tx.transactionData.delivererWasteAmounts.map(deliveredEl => {
        const isMassBalance = deliveredEl.amount && deliveredEl.magnitude;
        if (isMassBalance) {
            const amount = deliveredEl.amount instanceof MaterialAmount
                ? deliveredEl.amount
                : new MaterialAmount(
                    deliveredEl.amount,
                    deliveredEl.magnitude,
                    prefUnits,
                )
            const magnitude = deliveredEl.magnitude instanceof Magnitude
                ? deliveredEl.magnitude
                : new Magnitude(deliveredEl.magnitude);
            return {...deliveredEl, amount, magnitude};
        }
        const isDt = deliveredEl.numberOfDigitalTwins;
        if (isDt) {
            const digitalTwinsInventory = deliveredEl.digitalTwinsInventory.map(invItem => {
                const isMassBalance = invItem.amount && invItem.magnitude;
                if (isMassBalance) {
                    const amount = invItem.amount instanceof MaterialAmount
                        ? invItem.amount
                        : new MaterialAmount(
                            invItem.amount,
                            invItem.magnitude,
                            prefUnits,
                        )
                    const magnitude = invItem.magnitude instanceof Magnitude
                        ? invItem.magnitude
                        : new Magnitude(invItem.magnitude);
                    return {...invItem, amount, magnitude};
                }
                return invItem;
            });

            return {...deliveredEl, digitalTwinsInventory}

        }
        return deliveredEl
    });
    tx.transactionData.delivererWasteAmounts = delivered;

    const received = tx.transactionData.recipientWasteAmounts.map(receivedEl => {
        const isMassBalance = receivedEl.amount && receivedEl.magnitude;
        if (isMassBalance) {
            const amount = receivedEl.amount instanceof MaterialAmount
                ? receivedEl.amount
                : new MaterialAmount(
                    receivedEl.amount,
                    receivedEl.magnitude,
                    prefUnits,
                )
            const magnitude = receivedEl.magnitude instanceof Magnitude
                ? receivedEl.magnitude
                : new Magnitude(receivedEl.magnitude);
            return {...receivedEl, amount, magnitude};
        }
        const isDt = receivedEl.numberOfDigitalTwins;
        if (isDt) {
            const digitalTwinsInventory = receivedEl.digitalTwinsInventory.map(invItem => {
                const isMassBalance = invItem.amount && invItem.magnitude;
                if (isMassBalance) {
                    const amount = invItem.amount instanceof MaterialAmount
                        ? invItem.amount
                        : new MaterialAmount(
                            invItem.amount,
                            invItem.magnitude,
                            prefUnits,
                        )
                    const magnitude = invItem.magnitude instanceof Magnitude
                        ? invItem.magnitude
                        : new Magnitude(invItem.magnitude);
                    return {...invItem, amount, magnitude};
                }
                return invItem;
            });

            return {...receivedEl, digitalTwinsInventory}
        }

        return receivedEl;
    });
    tx.transactionData.recipientWasteAmounts = received;

    return tx;
}
