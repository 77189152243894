import Vue from 'vue';

import './toasted';

import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime);

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

