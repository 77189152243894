var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export class Pagination {
    constructor() {
        this.pageNumber = 1;
        this.pageSize = 25;
        this.totalCount = undefined;
        this.totalPages = undefined;
        this.sortOrder = 'DESC';
        const starterFilters = {
            txStates: []
        };
        this.filters = new TxPaginationFilters(starterFilters);
        this.sortOrder = 'DESC';
    }
    setPagination({ pageNumber, pageSize, totalCount }) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.totalCount = totalCount;
        this.totalPages = Math.max(Math.ceil(totalCount / pageSize), 1); // even though has 0 pages, always default to 1 page so the user can try fetching the first page
    }
    setPageNumber(pageNumber) {
        this.pageNumber = pageNumber;
    }
    setPageSize(pageSize) {
        this.pageSize = pageSize;
    }
    /**
     * @param {string} order - The sorting order, must be 'ASC' or 'DESC'
     */
    setSortOrder(order) {
        if (order !== 'ASC' && order !== 'DESC') {
            throw new Error("Invalid date order. Order must be 'ASC' or 'DESC'.");
        }
        this.sortOrder = order;
    }
    getQueryParams() {
        return `pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&sortOrder=${this.sortOrder}`;
    }
    getFilters() {
        return this.filters;
    }
    updateFilters(filters) {
        this.filters.setFilters(filters);
    }
}
export class TxPaginationFilters {
    constructor(filters) {
        this.organizationId = undefined;
        this.facilityIds = [];
        this.transactionTypes = [];
        this.startDate = undefined;
        this.endDate = undefined;
        this.txStates = [];
        if (filters) {
            this.setFilters(filters);
        }
    }
    setFilters(filters) {
        this.setOrganizationId(filters === null || filters === void 0 ? void 0 : filters.organizationId);
        this.setDateFilters(filters === null || filters === void 0 ? void 0 : filters.date);
        this.setFacilitiesFilters(filters === null || filters === void 0 ? void 0 : filters.facilities);
        this.setTxTypesFilters(filters === null || filters === void 0 ? void 0 : filters.txTypes);
        this.setTxStatesFilter(filters === null || filters === void 0 ? void 0 : filters.txStates);
    }
    /**
     * @param date - date range as an array of startDate = date[0] and endDate = date[1]
     */
    setDateFilters(date) {
        if (Array.isArray(date) && date[0] && date[1]) {
            this.startDate = date[0];
            this.endDate = this.setEndDate(date[1]);
        }
        else {
            this.startDate = undefined;
            this.endDate = undefined;
        }
    }
    // add 23:59:59 to the end of the date
    setEndDate(date) {
        const inputDate = new Date(date).getTime();
        const addTime = (3600 * 1000 * 24) - 1; // add 24 hours - 1 millisecond
        const outputDate = new Date(inputDate + addTime).toISOString();
        return outputDate;
    }
    setOrganizationId(organizationId) {
        if (organizationId) {
            this.organizationId = organizationId;
        }
    }
    setFacilitiesFilters(facilities) {
        if (!facilities) {
            this.facilityIds = [];
            return;
        }
        this.facilityIds = facilities;
    }
    setTxTypesFilters(txTypes) {
        if (!txTypes) {
            this.transactionTypes = [];
            return;
        }
        this.transactionTypes = txTypes;
    }
    setTxStatesFilter(txStates) {
        if (!txStates) {
            this.txStates = [];
            return;
        }
        this.txStates = txStates;
    }
    toJson() {
        const _a = this, { txStates, transactionTypes } = _a, rest = __rest(_a, ["txStates", "transactionTypes"]);
        let typesToFilterBy = [];
        if (transactionTypes.includes('RECEPTION')) {
            typesToFilterBy.push('UNCONFIRMED_RECEPTION', 'RECEPTION', 'DEPOSIT', 'DELIVERY_REQUEST');
        }
        if (transactionTypes.includes('DELIVERY')) {
            typesToFilterBy.push('UNCONFIRMED_DELIVERY', 'DELIVERY');
        }
        if (transactionTypes.includes('PROCESS')) {
            typesToFilterBy.push('PROCESS');
        }
        return Object.assign({ transactionStatuses: txStates, transactionTypes: typesToFilterBy }, rest);
    }
}
