import Rollbar from "rollbar";

let rollbar;

export default {
    setupRollbar(vue, environment, version, accessToken) {
        if (environment === "qa" || environment === 'demo' || environment === "prod") {

            rollbar = new Rollbar({
                accessToken: accessToken,
                codeVersion: version,
                environment,
                captureUncaught: true,
                captureUnhandledRejections: true,
            });
            vue.prototype.$rollbar = rollbar

            vue.config.errorHandler = (err, vm) => {
                vm.$rollbar.error(err);
                throw err;
            };
            // enabling code from without vue to throw silent errors that rollbar will report
            window.addEventListener('silent-error', ({ detail }) => {
              rollbar.error(detail);
            });
        } else {
            vue.prototype.$rollbar = {
              error: () => {}
            };
        }
    },
    configurePerson(id, email, environment) {
        if (environment === "qa" || environment === "demo" || environment === "prod") {
            rollbar.configure({
                payload: {
                    person: {
                        id,
                        email,
                    }
                }
            });
        }
    },
    getRollbarInstance() {
        return rollbar;
    }
}


