import axios from 'axios'

export default class {

    constructor(userService, facilityService) {
        this.userService = userService;
        this.facilityService = facilityService;
    }

    process(inputs, outputs, longitude, latitude, message, manualDateTime, files) {
        return axios.post(process.env.VUE_APP_WASTE_API_BASE_URL + "/api/v1/waste/transactions/process", {
            facilityId: this.facilityService.getSelectedFacilityId(),
            inputs,
            outputs,
            longitude,
            latitude,
            message,
            manualDateTime,
            files
        }, {
            headers: {
                'Authorization': 'Bearer ' + this.userService.token
            }
        }).then(response => response.data);
    }
}
