/**
 * The type of transaction.
 * A delivery request can eventually turn into a delivery, when accepted.
 * Note, the backend defines other Tx Types, including:
 * - Reception Request
 * - Targeted Organization
 * Which may either not exist, or are never used.
 */
export class TxType extends String {
    constructor() {
        super(...arguments);
        this.equals = (other) => other.toString() === this.toString();
    }
}
export const UNCONFIRMED_DELIVERY = new TxType('UNCONFIRMED_DELIVERY');
export const UNCONFIRMED_DELIVERY_REVERSION = new TxType('UNCONFIRMED_DELIVERY_REVERSION');
export const UNCONFIRMED_RECEPTION = new TxType('UNCONFIRMED_RECEPTION');
export const UNCONFIRMED_RECEPTION_REVERSION = new TxType('UNCONFIRMED_RECEPTION_REVERSION');
export const REMOTE_DELIVERY = new TxType('REMOTE_DELIVERY');
//Note: There's no QR_DELIVERY as it's a remote delivery from backend/system side.
export const ONSITE_DELIVERY = new TxType('ONSITE_DELIVERY');
export const ONSITE_RECEPTION = new TxType('ONSITE_RECEPTION');
export const REMOTE_RECEPTION = new TxType('REMOTE_RECEPTION');
export const RECEPTION = new TxType('RECEPTION');
export const PROCESS = new TxType('PROCESS');
export const PROCESS_REVERSION = new TxType('PROCESS_REVERSION');
export const DEPOSIT = new TxType('DEPOSIT');
export class TxTypes extends Array {
    constructor() {
        super(...arguments);
        this.isValid = (elem) => {
            return this.some(el => el.equals(elem));
        };
        this.get = (elem) => {
            return this.find(el => el.equals(elem));
        };
    }
}
export const TX_TYPES = new TxTypes(UNCONFIRMED_DELIVERY, UNCONFIRMED_DELIVERY_REVERSION, UNCONFIRMED_RECEPTION, UNCONFIRMED_RECEPTION_REVERSION, ONSITE_DELIVERY, ONSITE_RECEPTION, REMOTE_DELIVERY, REMOTE_RECEPTION, RECEPTION, PROCESS, PROCESS_REVERSION, DEPOSIT);
