import {apiService} from "@/main";

export default class {
    organizationEndpoint = process.env.VUE_APP_ORGANIZATION_API_BASE_URL + '/api/v1/organization';

    constructor(userService) {
        this.userService = userService;
    }

    async getOrganizationList() {
        const response = await apiService.call('GET', `${this.organizationEndpoint}/organizations`)
        return response.organizations;
    }

    async getOrganization(organizationId) {
        return await apiService.call('GET', `${this.organizationEndpoint}/organizations/${organizationId}`);
    }
}
