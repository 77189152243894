import {apiService} from "@/main";

export default class {
    userService;
    facilityService;
    eventBus;
    depositEndpoint = process.env.VUE_APP_COLLECTION_API_BASE_URL + '/api/v1/deposit'
    depositAppEndpoint = process.env.VUE_APP_DEPOSIT_APP_API_BASE_URL + '/api/v1/deposit-app'

    constructor(userService, facilityService, eventBus) {
        this.userService = userService;
        this.facilityService = facilityService;
        this.eventBus = eventBus;
    }

    async isCollectionPoint() {
        return await apiService.call('GET', `${this.depositEndpoint}/collection-points/is-collection-point/${this.facilityService.getSelectedFacilityId()}`);
    }

    async getTransactionsHistory(stellarId) {
        return await apiService.call('GET', `${this.depositEndpoint}/balances/history?facilityAccountId=${stellarId}`);
    }

    async acceptDepositWithoutApp(massBalanceDefinition, longitude, latitude, amount, manualDeliverer, message, manualDateTime, files, payment) {
        return await apiService.call('POST', `${this.depositEndpoint}/deposits`, {
            facilityId: this.facilityService.getSelectedFacilityId(),
            massBalanceDefinition,
            longitude,
            latitude,
            amount,
            manualDeliverer,
            message,
            manualDateTime,
            files,
            payment
        });
    }

    async callDepositAppService(rawData, facilityName) {
        // Check if data is using legacy api model, or if using batch model
        const legacy = !!rawData.selectedMaterial;

        const collectionReceiver = {
            id: this.facilityService.getSelectedFacilityId(),
            name: facilityName
        }

        const paymentReceiver = {
            id: rawData.receiveFromId,
            name: rawData.receiveFrom
        }

        const payload = {
            collectionReceiver,
            paymentReceiver,
            date: rawData.selectedDate,
            payment: {
                amount: Number(rawData.payment.amount),
                currency: rawData.payment.currency
            },
            deposits: legacy ? this.depositPayloadFromLegacyModel(rawData) : this.depositPayloadFromBatchModel(rawData)
         }
        return await apiService.call('POST', `${this.depositAppEndpoint}/collection/manual`, payload);

    }

    depositPayloadFromLegacyModel(rawData) {
        const deposits = [
            {
                material: rawData.selectedMaterial.name,
                magnitude: rawData.selectedMaterial.magnitude,
                amount: Number(rawData.amount),
                payment: Number(rawData.payment.amount),
            }
        ]
        return deposits;
     }

    depositPayloadFromBatchModel(rawData) {
        return rawData.inventoryItems.map(item => {
            return {
                material: item.massBalanceDefinition.plasticName, // Maybe we should concatenate all the massBalanceProperties here?
                magnitude: item.magnitude,
                amount: Number(item.amount),
                payment: Number(item.payment.amount),
            }
        })
     }
}
